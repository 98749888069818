import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrDatagrid, ClrForm } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { first } from "rxjs/operators";

import { ArrayMethodsCallbackPipe } from "src/app/pipes/array-methods-callback.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { LinieDrehungPipe } from "src/app/pipes/linie-drehung.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { LinieDrehungService } from "src/app/services/Shared/linie-drehung.service";
import { LinieValidationManager } from "src/app/shared/classes/linie-validation-manager";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { BasedatamodalComponent } from "../../_modals/basedatamodal/basedatamodal.component";

@Component({
  selector: "app-bauwerksdialog",
  templateUrl: "./bauwerksdialog.component.html",
  styleUrls: ["./bauwerksdialog.component.scss"],
  imports: [CommonModule, ClarityModule, BasedatamodalComponent, PreviewThumbnailsComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, LinieDrehungPipe, ArrayMethodsCallbackPipe, HideIDColumnsPipe, TablePrettyPrintPipe],
  providers: [LinieDrehungPipe],
  standalone: true
})

export class BauwerksDialogComponent extends LinieValidationManager implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataRefresh: EventEmitter<any>;

  @ViewChild(PreviewThumbnailsComponent, { static: true })
    private preview: PreviewThumbnailsComponent
  ;
  @ViewChild(ClrDatagrid, { static: false })
    private grid: ClrDatagrid
  ;
  @ViewChild(ClrForm, { static: true })
    private clrForm: ClrForm
  ;

  @ViewChild(BasedatamodalComponent) modal: BasedatamodalComponent;

  private otypid: number;
  private ostammid: number;

  private _selectedBauw: any;
  protected get selectedBauw(): any {
    return this._selectedBauw;
  }
  protected set selectedBauw(value: any) {
    this._selectedBauw = value;
    this.formBauTop.reset();
    if (!value) return;
    this.title = 'Bauwerke verwalten';
    this.isModeNew = false;
    this.formBauTop.enable();
    this.formBauTop.patchValue(value);
    this.clrForm.markAsTouched();
    this.getBilder(value.bild, value.id);
  }
  protected exceptStartEnd =
    (col: any) => !['von', 'bis'].includes(col.id)
  ;
  protected table: any;
  protected artListe: Array<any>;
  protected isModeNew: boolean = false;
  protected title: string = "Bauwerke verwalten";
  protected isInspektion: boolean = false;
  private bilder: {[id: number]: any[]} = {};
  protected deletedFiles: string[] = [];

  @Input() set setotypid(inotypid: number) {
    this.otypid = inotypid;
  }

  @Input() set setostammid(inostammid: number) {
    this.ostammid = inostammid;
    this.isInspektion = true;
    this.freshReload();
  }

  @Input() set setstammData(stammData: any) {
    this.startmeter = stammData.startmeter;
    this.endmeter = stammData.endmeter;
    this.direction = this.initLinieValidators(this.startmeter, this.endmeter);
  }

  public set data(dataIn: any) {
    this.table = dataIn.table;
    this.ostammid = dataIn.ostamm;
    this.otypid = dataIn.otypid;

    this.startmeter = dataIn.stammData.startmeter;
    this.endmeter = dataIn.stammData.endmeter;
    this.direction = this.initLinieValidators(this.startmeter, this.endmeter);

    this.dataRefresh = dataIn.dataRefresh;
  }

  protected formBauTop = new UntypedFormGroup({
    von: new UntypedFormControl(""),
    bis: new UntypedFormControl(""),
    bauwerksart: new UntypedFormControl("", [Validators.required]),
    bezeichnung: new UntypedFormControl(""),
    lage: new UntypedFormControl(""),
    gpskoordinaten: new UntypedFormControl(""),
  });

  private startmeter: number = 0;
  private endmeter: number = 0;
  protected direction: number = 1;

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    protected drehungPipe: LinieDrehungPipe,
    protected drehung: LinieDrehungService,
  ) {
    super({ start: 'von', end: 'bis' }, true);
    this.form = this.formBauTop;
  }

  async ngOnInit() {
    this.formBauTop.disable();
    this.artListe = await firstValueFrom(
      this.apiService.getBauwerksArten(this.otypid)
    );
  }

  openWertelisteBauwerk(){
    let inData={
      titel: this.mrTranslate.transform('Bauwerke'),
      tabelle: "SBAUW",
      OTYPID: this.otypid,
      tname: '',
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(async res => {
      if (res.isChanged == true){
        this.artListe = await firstValueFrom(
          this.apiService.getBauwerksArten(this.otypid)
        );
      }
      this.modal.close();
    });
  }


  protected addBauw() {
    this.isModeNew = true;
    this.grid?.selection.clearSelection();
    //this.title = 'Bauwerke verwalten - Neu anlegen';
    this.formBauTop.enable();


    setTimeout(() => {
      this.preview.clearPreview();
    });
  }

  protected deleteTitelBauw() {
    const text = this.mrTranslate.transform("Bauwerk wirklich löschen?");
    if (confirm(text) && this.selectedBauw) {

      this.apiService
        .deleteBauw(parseInt(this.selectedBauw.id))
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status === "success") {
            this.toastr.success(this.mrTranslate.transform("Daten gelöscht"));
            this.table.rows.splice(
              this.table.rows.indexOf(this.selectedBauw),
              1
            );
            this.formBauTop.disable();
            this.grid?.selection.clearSelection();
            this.dataRefresh?.emit(true);
          } else {
            this.toastr.error(this.mrTranslate.transform("Fehler beim Löschen"));
          }

        });
    }
  }

  private async freshReload() {
      this.apiService
      .getBauw(this.ostammid)
      .pipe(first())
      .subscribe((bawerkeTable: any) => {
        if (!this.table?.columns?.length)
          this.table = bawerkeTable;
        else this.table.rows = bawerkeTable.rows;
      });
  }

  protected saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.formBauTop.valid) this.saveBauw();
  }

  private async saveBauw() {
    const sendObj = {
      DoInsert: this.isModeNew,
      table: this.formBauTop.value,
      ID: this.selectedBauw?.id ?? -1,
      images: await this.preview.packUnsavedFiles(),
      savedFileNames: this.preview.saved.map((file) => file.name).join("|"),
      delFile: this.deletedFiles
    };

    this.apiService
      .setBauw(this.ostammid, sendObj)
      .pipe(first())
      .subscribe(({ status, neuRow }: any) => {
        if (status == "OK") {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.freshReload();
          this.isModeNew = false;
          const neuItem = { ...this.selectedBauw, ...sendObj.table, ...neuRow};
          if (this.isModeNew)
            this.table.rows.unshift(neuItem);
          else {
            this.table.rows.splice(
              this.table.rows.indexOf(this.selectedBauw),
              1, neuItem
            );
          }
          this.resetPreview(neuItem.id);
          this.grid.singleSelected = neuItem;
          this.dataRefresh?.emit(true);

        } else {
          this.toastr.error(
            this.mrTranslate.transform('Fehler beim Löschen')
          );
        }
      });
  }

  // private refresh() {
  //   this.isModeNew = false;
  //   if (this.grid) this.grid.selection.clearSelection();
  //   this.formBauTop.reset();
  //   this.formBauTop.disable();
  //   this.freshReload();
  //   this.dataRefresh?.emit(true);
  // }

  public checkValid(startMeter: number, endMeter: number) {
    this.startmeter = startMeter;
    this.endmeter = endMeter;
    this.direction = this.initLinieValidators(this.startmeter, this.endmeter);
    const valid = this.table.rows.every(row => this.direction == 1
      ? row.von >= startMeter && row.bis <= endMeter
      : row.von <= startMeter && row.bis >= endMeter
    );
    if (!valid) this.toastr.error(
      this.mrTranslate.transform('Berücksichtigen Sie die Start- & Endmeter'),
      this.mrTranslate.transform('Bauwerke')
    );
    return valid;
  }

  private async getBilder(bilder: string, id: number) {
    if (!this.bilder[id]) {
      try {
        this.preview.clearPreview();
        let isFilenames: boolean;
        const files = !bilder ? [] : (isFilenames = bilder.includes('.'))
          // * gespeichert in Passbilder Ordner; aus der Back-End holen
          ? await firstValueFrom(this.apiService.getPassBilder(bilder))
          // * alte Version; base64 in row; wird ab jetzt im Ordner gespeichert
          : bilder.split('|').map(base64 => ({ name: ".png", base64 }));

        this.preview.unpackFiles(files, isFilenames);
        this.bilder[id] = this.preview.rawPreviewThumbnails;
      } catch (error) {
        this.toastr.error(this.mrTranslate.transform('Etwas ist schief gelaufen'));
        console.log(error);
      }
    }
    else setTimeout(() => {
      this.preview.rawPreviewThumbnails = this.bilder[id];
    });
  }

  private async resetPreview(id: number) {
    if (this.preview.unsaved.length)
      this.bilder[id] = null;
    else if (this.deletedFiles.length) {
      this.bilder[id] = this.preview.rawPreviewThumbnails;
      this.deletedFiles = [];
    }
  }

  protected close() {
    this.closedialog.next(true);
  }
}
