<div class="filter-wrapper">
    <div class="filter-top">
        <select clrSelect name="options" (change)="onChange($event.target.value)">
            <option *ngFor="let op of availableOperators" [value]="op.value" [selected]="op.value === selectedOperator">
                {{ op.label }}
            </option>
        </select>

        <input type="text" class="clr-input" [placeholder]="'Filter ' + column.label" [(ngModel)]="searchText"
            (input)="updateFilteredPreview()" />
    </div>

    <clr-datagrid [(clrDgSelected)]="tempSelectedValues" (clrDgSelectedChange)="onSelectionChanged($event)">
        <clr-dg-column>Preview</clr-dg-column>
        <clr-dg-row *ngFor="let row of filteredPreview; trackBy: trackByPreview" [clrDgItem]="row">
            <!-- <clr-dg-cell>{{ row }}</clr-dg-cell> -->
            <clr-dg-cell [innerHTML]="getValue(row, column)"></clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>

    <div class="filter-actions" style="margin-top: 0.5rem; display: flex; justify-content: flex-end; gap: 0.5rem;">
        <button class="btn btn-sm btn-outline" (click)="onCancel()">Cancel</button>
        @if(!immediate){
        <button class="btn btn-sm btn-primary" (click)="onApply()">Apply</button>
        }
    </div>
</div>