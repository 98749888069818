<div class="overlay-inner-wrapper">
  <ng-container *ngIf="!isInspektion">
    <header class="header-2">
      <div class="branding">
          <span class="title">{{title | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <a href="javascript:void(0);" class="nav-link nav-icon">
          <clr-icon  shape="times" (click)="close()"></clr-icon >
        </a>
      </div>
    </header>
  </ng-container>

  <div id="bauwerksWrapper" class="w-100-p padding-10-lr padding-12-b scroll-y"
    [ngClass]="isInspektion ? 'h-100' :  'h-60'">
    <div class="clr-row nxm">
      <div class="clr-col-auto margin-12-tb">
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-icon btn-success"
            [title]="'Neu' | mrTranslate"
            (click)="addBauw()" [disabled]="isModeNew">
            <clr-icon shape="file"></clr-icon>
          </button>
          <button class="btn btn-icon btn-danger"
            [title]="'Löschen' | mrTranslate"
            [disabled]="!selectedBauw" (click)="deleteTitelBauw()">
            <clr-icon shape="times"></clr-icon>
          </button>

          <button class="btn btn-icon margin-12-l"
            [title]="'Speichern' | mrTranslate"
            [disabled]="!(selectedBauw || isModeNew)" (click)="f.ngSubmit.emit()">
            <clr-icon shape="floppy"></clr-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- Top-Sektion - Felder zum eintragen -->
     <!-- <div class="clr-row nxm margin-18-t">
    </div> -->
    <form [formGroup]="formBauTop" #f="ngForm"
      (ngSubmit)="saveIfValid()"
      clrForm clrLayout="vertical"
      class="width100 padding-12-lr"
      [clrLabelSize]="12">
      <div *ngIf="formBauTop.errors?.['not-in-Direction'] as err"
        class="clr-row nxm clr-col-12 clr-col-sm-10 clr-col-md-8 clr-col-lg-6">
        <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
          <clr-alert-item>
            <span class="alert-text">
              {{ "Der Startmeter kann nicht " + (err.upwards ? "größer" : "kleiner") + " als der Endmeter sein" | mrTranslate }}
            </span>
          </clr-alert-item>
        </clr-alert>
      </div>
      <div class="clr-row nxm">
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6" [class.clr-order-2]="drehung.isOn">
          <clr-input-container>
            <label for="von">{{(!drehung.isOn ? "von" : "bis") | mrTranslate}}</label>
            <input type="number" clrInput name="von" formControlName="von"
              [value]="formBauTop.get('von').value | drehung: drehung.summe"
              (change)="formBauTop.get('von').setValue(
                drehungPipe.back($event.target.value, drehung.summe)
              )" [step]="0.1"/>
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
            <clr-control-error *clrIfError="'min'; error as err">
              {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
            </clr-control-error>
            <clr-control-error *clrIfError="'max'; error as err">
              {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
            </clr-control-error>
          </clr-input-container>
        </div>
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6" [class.clr-order-1]="drehung.isOn">
          <clr-input-container>
            <label for="bis">{{(!drehung.isOn ? "bis" : "von") | mrTranslate}}</label>
            <input type="number" clrInput name="bis" formControlName="bis"
              [value]="formBauTop.get('bis').value | drehung: drehung.summe"
              (change)="formBauTop.get('bis').setValue(
                drehungPipe.back($event.target.value, drehung.summe)
              )" [step]="0.1"/>
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
            <clr-control-error *clrIfError="'min'; error as err">
              {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
            </clr-control-error>
            <clr-control-error *clrIfError="'max'; error as err">
              {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
            </clr-control-error>
          </clr-input-container>
        </div>
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6 clr-order-3">
          <clr-select-container>
            <label for="art" class="d-f row w-100-p">
              <span class="clr-col nxp ellipsis">
                {{ "Bauwerksart" | titlecase | mrTranslate }}</span>
              <button class="btn btn-sm btn-icon btn-outline clr-col-auto"
                style="margin: -7px 0; float: inline-end;" tabindex="-1"
                type="button" [title]="'Tabelle verwalten' | mrTranslate"
                (click)="openWertelisteBauwerk()">
                <clr-icon shape="pencil"></clr-icon>
              </button>
            </label>



            <!-- <label for="art">
              {{"Bauwerksart" | mrTranslate}}
            </label> -->
            <select clrSelect name="art" formControlName="bauwerksart">
              <option *ngFor="let wert of artListe" [value]="wert">
                {{wert | mrTranslate}}
              </option>
            </select>
            <clr-control-error *clrIfError="'required'">
              {{ "Feld 'Bauwerksart' muss ausgefüllt werden!" | mrTranslate }}
            </clr-control-error>
          </clr-select-container>
        </div>
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6 clr-order-3">
          <clr-input-container>
            <label for="bezeichnung">{{"Bezeichnung" | mrTranslate}}</label>
            <input type="text" clrInput name="bezeichnung" formControlName="bezeichnung"/>
          </clr-input-container>
        </div>
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6 clr-order-3">
          <clr-input-container>
            <label for="lage">{{"Lage" | mrTranslate}}</label>
            <input type="text" clrInput name="lage" formControlName="lage"/>
          </clr-input-container>
        </div>
        <div class="clr-col-md-2 clr-col-sm-4 clr-col-6 clr-order-3">
          <clr-input-container>
            <label for="gps">{{"GPS" | mrTranslate}}</label>
            <input type="text" clrInput name="gps" formControlName="gpskoordinaten"/>
          </clr-input-container>
        </div>
      </div>
    </form>
    <clr-datagrid *ngIf="table"
      class="datagrid-compact dg-overflow-fix"
      style="max-height: calc(100svh - 400px);"
      [clrDgRowSelection]="true"
      [(clrDgSingleSelected)]="selectedBauw">
      <clr-dg-column [clrDgField]="(!drehung.isOn ? 'von' : 'bis')"
        [clrDgSortOrder]="direction * (drehung.isOn ? -1 : 1)">
        {{ 'Startmeter' | titlecase | mrTranslate }}
      </clr-dg-column>
      <clr-dg-column [clrDgField]="(!drehung.isOn ? 'bis' : 'von')">
        {{ 'Endmeter' | titlecase | mrTranslate }}
      </clr-dg-column>
      <clr-dg-column *ngFor="let column of table['columns'] | array:'filter': exceptStartEnd" [clrDgField]="column.id">
        <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns: ['b_parentid','bild']}">
          {{ column.id | titlecase | mrTranslate }}
        </ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let row of table['rows']" [clrDgItem]="row">
        <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'von' : 'bis')] | drehung: drehung.summe | tablePrettyPrint">
        </clr-dg-cell>
        <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'bis' : 'von')] | drehung: drehung.summe | tablePrettyPrint">
        </clr-dg-cell>
        <clr-dg-cell class="name-cell" *ngFor="let cell of table['columns'] | array:'filter': exceptStartEnd"
          [innerHTML]="row[cell.id] | tablePrettyPrint">
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>{{table['rows']?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
    </clr-datagrid>

    <div class="clr-row nxm padding-24-b">
      <div class="clr-col-12">
        <app-preview-thumbnails
          [readonly]="!(selectedBauw || isModeNew)"
          [acceptTypes]="'image/*'"
          [maxAmount]="6"
          (deleteFile)="deletedFiles.push($event)">
        </app-preview-thumbnails>
      </div>
    </div>
  </div>
</div>
<app-basedatamodal></app-basedatamodal>
