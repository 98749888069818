import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  OnDestroy,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import shajs from "sha.js";
import { AuthService } from "../../services/Auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { catchError, finalize, firstValueFrom, interval, of, Subject, tap } from "rxjs";
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import jwtDecode from "jwt-decode";
import { APIService } from "src/app/services/APIService/api.service";
import { environment } from "src/environments/environment";
import { EncryptionService } from "src/app/services/encryption.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../pipes/mr-translate.pipe";
import { NgIf, NgFor } from "@angular/common";
import { ClrCommonFormsModule, ClrInputModule, ClrModalModule, ClrProgressBarModule, ClrSelectModule } from "@clr/angular";
import { MsalConfigService } from "src/app/services/msal-config.service";

@Component({
  selector: "mrpro-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  imports: [
    FormsModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    ClrInputModule,
    NgIf,
    ClrModalModule,
    ClrProgressBarModule,
    ClrSelectModule,
    NgFor,
    MrTranslatePipe_1,
  ],
  standalone: true
})
export class LoginComponent implements OnInit, OnDestroy {
  loginGroup = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    password: new UntypedFormControl("", [Validators.required]),
  });

  userName: string = '';
  isAuthenticated: boolean = false;
  error: Error | null = null;

  returnUrl: string;
  isDarkThemeAktiv = localStorage.getItem("theme") == "dark";
  pemail: string;
  pcode: string;
  pw: string;
  pwsha: string;
  generatedNumber: number;
  userid: number;
  progressbarValue = 100;
  dateSec: string;
  curSec1: number = 0;
  _seconds: number = 0;
  tminutes: number = 0;
  tseconds: number = 0;
  doexittimer: boolean;
  dbs: any;
  dom: string;
  signIn: any;
  is2FA: boolean = false;
  mobil: string;
  private destroy$ = new Subject<void>();

  googleentrykey: string = '';
  googleqrimage: any;
  googleSetupShow: boolean = false;
  googleCodeShow: boolean = false;
  usegoogle: string = "0";



  @ViewChild("excode") excode: ElementRef;
  @ViewChild("exmail") exmail: ElementRef;
  @ViewChild("exnewpw") exnewpw: ElementRef;
  @ViewChild("exnewpwconf") exnewpwconf: ElementRef;
  @ViewChild("seldb") seldb: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private authService: AuthService,
    private msalConfigService: MsalConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private apiservice: APIService,
    private encryptaes: EncryptionService,
    private domSanitizer: DomSanitizer,
  ) {
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isforgotPWModalVisible = false;
  isCodeModalVisible = false;
  isNewPasswordVisible = false;
  isRSVDatabaseSelectVisible = false;
  isTenentMailVisible = false;

  showOktaLogin = environment.okta.showLogin;
  showMsalLogin = environment.msalConfig.showLogin;

  async ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    this.dom = "2257f29edfa4372fe830a5d954e79bc56254472a91e75e0370da1abcc3497625";
  }

  timer2FAfinished: boolean = false;
  public timerInterval: any;

  timer2FA(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let statSec: number = 60;
    let textSec: any = '0';
    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      console.log(`${prefix}${Math.floor(seconds / 60)}:${textSec}`);

      if (seconds == 0) {
        this.timer2FAfinished = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }


  do2FA($event) {
    if (this.excode.nativeElement.value.toString().length != 6) {
      this.toastr.error(this.mrTranslate.transform("Bestätigungscode ist ungültig"));
      return;
    }
    this.pcode = this.excode.nativeElement.value;
    if (this.pcode == this.generatedNumber.toString()) {
      this.is2FA = false;
      this.authService.handle2FALogin();
    } else {
      this.excode.nativeElement.value = "";
      this.toastr.error(this.mrTranslate.transform("Bestätigungscode ist ungültig"));
    }
  }

  do2FAsendSMS() {
    this.generatedNumber = Math.floor(100000 + Math.random() * 999999);
    this.authService.send2FASMS(this.mobil, this.generatedNumber.toString())
      .subscribe((data: any) => {
        if (data.success == true) {
          setTimeout(() => {
            clearInterval(this.timerInterval);
            this.is2FA = true;
            this.timer2FAfinished = false;
            this.timer2FA(1);
          }, 200);
        }
        else {
          this.authService.handle2FALogin();
        }
      });
  }


  doLoginIntern(event) {
    let karteFileID = JSON.parse(localStorage.getItem("karteFileID"));
    let db = this.seldb.nativeElement.value;
    let pwhash = shajs("sha256")
      .update(this.loginGroup.value.password)
      .digest("hex");
    let email = this.loginGroup.value.email;
    this.authService.login(email, pwhash, db, karteFileID).subscribe(
      (data: any) => {
        if (data == true) {

          if (this.authService.twofa == 1) {

            let token = jwtDecode(this.authService.tempdata.token);
            this.mobil = token["mobil"];
            this.isRSVDatabaseSelectVisible = false;

            if (this.mobil == 'rsvintern') {
              this.authService.handle2FALogin();
              this.toastr.success(
                this.mrTranslate.transform("Erfolgreich eingeloggt")
              );
              return;
            }


            if (this.mobil == '') {
              this.toastr.error(this.mrTranslate.transform("Ein Anmeldung ist nicht möglich. Für die 2 Faktor Authentifizierung fehlt die Mobilnummer in Ihrem Account. Bitte wenden Sie sich an Ihren Administrator."));
            }
            else {
              this.do2FAsendSMS();
            }

            return;
          }

          if (this.authService.twofa == 2) {
            this.isRSVDatabaseSelectVisible = false;

            let token = jwtDecode(this.authService.tempdata.token);
            let items = {
              email: token["email"],
              db: token["db"]
            };

            this.usegoogle = localStorage.getItem(token["db"] + "_" + token["email"]) || "0";

            if (this.usegoogle == "0") {
              this.googleSetupShow = false;

              this.apiservice.setupGoogleCode(items).subscribe(
                (data: any) => {
                  if (data) {
                    if (data.error != undefined) {
                      this.toastr.error(this.mrTranslate.transform(data.error));
                      return;
                    }
                    else {
                      this.googleentrykey = data.ManualEntryKey;
                      this.googleqrimage = this.domSanitizer.bypassSecurityTrustUrl(data.QrCodeSetupImageUrl);
                      this.googleSetupShow = true;

                      localStorage.setItem(token["db"] + "_" + token["email"], "1");

                      setTimeout(() => {
                        this.input.nativeElement.focus()
                      }, 20);


                    }
                  } else {
                    this.toastr.error(
                      this.mrTranslate.transform(
                        "Es ist ein Fehler aufgetreten."
                      )
                    );
                    return;
                  }
                });
            } else {
              this.openGoogle();
            }
            return;

          }

          this.toastr.success(
            this.mrTranslate.transform("Erfolgreich eingeloggt")
          );

        } else {

          if (data.info != undefined) {
            if (data.info != '') {
              this.toastr.info(this.mrTranslate.transform(data.info));
              this.isRSVDatabaseSelectVisible = false;
              return;
            }
          }

          if (data.error != undefined) {
            if (data.error == 'Server is not available') {
              this.toastr.info(this.mrTranslate.transform("MR.pro® ist zur Zeit leider nicht erreichbar. Wir sind mit der Lösung des Problems beschäftigt. Bitte versuchen sie es später noch einmal."));
            }
            else {
              this.toastr.error(this.mrTranslate.transform("Login fehlgeschlagen"));
            }
          }
        }

        this.isRSVDatabaseSelectVisible = false;
      },
      (_: HttpErrorResponse) => { }
    );
  }


  async msalLogin() {
    if (localStorage.getItem("msal") != null) {
      let msal = JSON.parse(this.encryptaes.decryptionAES(localStorage.getItem("msal")));
      this.apiservice.aud = msal.appid;
      this.apiservice.tid = msal.tenid;
      await this.doMSALLogin(msal);
    }
    else {
      this.isTenentMailVisible = true;
    }
  }

  async doMSALLogin(msal: { appid: string; tenid: string; db?: any }) {
    await this.msalConfigService.setConfig(msal.tenid, msal.appid);
    const instance = await this.msalConfigService.getInstance();

    // Ensure interaction is removed! That can happen when we log out or when an error occurs during login
    sessionStorage.removeItem("msal.interaction.status");

    // TODO: Aktuell müssen wir das mit Popup machen, weil wir in Azure glaube ich keine Root Domain wie http://localhost:4201/ oder https://dev.mrpro.cloud/ eintragen können. Ist aber auch nicht verkehrt? :)
    const authenticationResult = await instance.loginPopup({
      scopes: ['user.read'],
      redirectUri: `${window.location.protocol}//${window.location.host}/login`
    });

    instance.setActiveAccount(authenticationResult.account);
    this.isTenentMailVisible = false
    this.router.navigateByUrl('/');
  }

  async getMSALMail(): Promise<void> {
    this.pemail = this.exmail.nativeElement.value?.trim();

    if (!this.pemail) {
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine gültige Email Adresse ein"));
      return;
    }

    try {
      const data: any = await firstValueFrom(this.apiservice.getnewTenantId(this.pemail));

      if (!data) {
        this.toastr.error(this.mrTranslate.transform("Es ist im System keine Konfiguration für Azure AD hinterlegt."));
        this.isTenentMailVisible = false;
        return;
      }

      if (data.error) {
        this.toastr.error(this.mrTranslate.transform(data.error));
        return;
      }

      const msal = {
        appid: data.ADAppid,
        tenid: data.ADTenant,
        db: data.db,
      };

      localStorage.setItem('msal', this.encryptaes.encryptionAES(JSON.stringify(msal)));

      this.isTenentMailVisible = false;
      this.toastr.success(this.mrTranslate.transform("Konfiguration für Azure AD wurde gespeichert."));

      try {
        await this.doMSALLogin(msal);
      } catch (loginError) {
        console.error("Login failed", loginError);
        this.toastr.error(this.mrTranslate.transform("Anmeldung bei Microsoft Azure ist fehlgeschlagen."));
      }

    } catch (apiError) {
      console.error("API call failed", apiError);
      this.toastr.error(this.mrTranslate.transform("Fehler beim Abrufen der Azure-Konfiguration."));
      this.apiservice.logError(apiError).subscribe({
        next: () => console.log("Error logged successfully."),
        error: (err) => console.error("Failed to log error", err)
      });

      this.apiservice.logError(apiError).pipe(
        catchError(err => {
          console.error("Failed to log error", err);
          return of(null);
        }),
        finalize(() => {
          this.toastr.error(this.mrTranslate.transform("Fehler beim Abrufen der Azure-Konfiguration."));
        })
      ).subscribe();
    }
  }


  async oktaLogin() {
    await this.oktaAuth.signInWithRedirect({

      scopes: ['openid', 'profile', 'email', 'groups-mrpro']
    }).then(res => {
    });
  }

  doLogin(event) {

    let karteFileID = JSON.parse(localStorage.getItem("karteFileID"));


    let pwhash = shajs("sha256")
      .update(this.loginGroup.value.password)
      .digest("hex");
    let email = this.loginGroup.value.email;

    if (
      shajs("sha256")
        .update(email.substring(email.indexOf("@") + 1, 100))
        .digest("hex") == this.dom
    ) {
      this.authService
        .internlogin(
          email,
          pwhash,
          shajs("sha256")
            .update(email.substring(email.indexOf("@") + 1, 100))
            .digest("hex"),
          karteFileID,
        )
        .subscribe(
          (data: any) => {
            if (data.check == true) {
              this.dbs = data.allDB.rows;

              if (this.dbs != undefined) {
                setTimeout(() => {
                  this.isRSVDatabaseSelectVisible = true;
                }, 200);
              } else {
                if (data.info != undefined) {
                  if (data.info != '') {
                    this.toastr.info(this.mrTranslate.transform(data.info));
                    this.isRSVDatabaseSelectVisible = false;
                    return;
                  }
                }
                if (data.error == 'Server is not available') {
                  this.toastr.info(this.mrTranslate.transform("MR.pro® ist zur Zeit leider nicht erreichbar. Wir sind mit der Lösung des Problems beschäftigt. Bitte versuchen sie es später noch einmal."));
                }
                else {
                  this.toastr.error(this.mrTranslate.transform("Login fehlgeschlagen"));
                }
              }
            } else {
              if (data.info != undefined) {
                if (data.info != '') {
                  this.toastr.info(this.mrTranslate.transform(data.info));
                  this.isRSVDatabaseSelectVisible = false;
                  return;
                }
              }
              if (data.error == 'Server is not available') {
                this.toastr.info(this.mrTranslate.transform("MR.pro® ist zur Zeit leider nicht erreichbar. Wir sind mit der Lösung des Problems beschäftigt. Bitte versuchen sie es später noch einmal."));
              }
              else {
                this.toastr.error(this.mrTranslate.transform("Login fehlgeschlagen"));
              }
            }
          },
          (error: HttpErrorResponse) => { }
        );
    } else {
      this.authService.loginGetDatabases(email, pwhash).subscribe(
        (data: any) => {
          if (data.check == true) {
            this.dbs = data.allDB.rows;

            if (this.dbs != undefined) {
              setTimeout(() => {
                this.isRSVDatabaseSelectVisible = true;
              }, 200);
            }
            else {
              this.login();
            }
          }
          else {
            this.login();
          }
        });
    }
  }

  verifyGoogleCode() {

    if (this.input.nativeElement.value.toString().length != 6) {
      this.toastr.error(
        this.mrTranslate.transform("Bestätigungscode ist ungültig")
      );
      return;
    }

    let token = jwtDecode(this.authService.tempdata.token);
    let items = {
      code: this.input.nativeElement.value,
      email: token["email"],
      db: token["db"]
    };


    this.apiservice.verifyGoogleCode(items).subscribe(
      (data: any) => {
        if (data) {
          if (data.error != undefined) {
            this.toastr.error(this.mrTranslate.transform(data.error));
            return;
          }
          else {

            if (data.success) {
              this.googleSetupShow = false;
              this.authService.handle2FALogin();
            }
            else {
              this.toastr.error(
                this.mrTranslate.transform(
                  "Code ist ungültig"
                )
              );

              this.input.nativeElement.value = '';
              setTimeout(() => {
                this.input.nativeElement.focus()
              }, 20);

              return;
            }
          }
        } else {
          this.toastr.error(
            this.mrTranslate.transform(
              "Es ist ein Fehler aufgetreten."
            )
          );
          return;
        }
      });


  }

  openGoogle() {
    this.googleCodeShow = true;

    setTimeout(() => {
      this.input.nativeElement.focus()
    }, 20);

  }



  login() {

    let pwhash = shajs("sha256").update(this.loginGroup.value.password).digest("hex");
    let email = this.loginGroup.value.email;
    let karteFileID = JSON.parse(localStorage.getItem("karteFileID"));
    this.authService.login(email, pwhash, undefined, karteFileID).subscribe(
      (data: any) => {
        if (data == true) {

          if (this.authService.twofa == 1) {

            let token = jwtDecode(this.authService.tempdata.token);
            this.mobil = token["mobil"];
            this.isRSVDatabaseSelectVisible = false;

            if (this.mobil == '') {
              this.toastr.error(this.mrTranslate.transform("Ein Anmeldung ist nicht möglich. Für die 2 Faktor Authentifizierung fehlt die Mobilnummer in Ihrem Account. Bitte wenden Sie sich an Ihren Administrator."));
            }
            else {
              this.do2FAsendSMS();
            }

            return;
          }
          if (this.authService.twofa == 2) {
            let token = jwtDecode(this.authService.tempdata.token);

            let items = {
              email: token["email"],
              db: token["db"]
            };

            this.usegoogle = localStorage.getItem("usegoogle_" + token["email"]) || "0";

            if (this.usegoogle == "0") {
              this.googleSetupShow = false;

              this.apiservice.setupGoogleCode(items).subscribe(
                (data: any) => {
                  if (data) {
                    if (data.error != undefined) {
                      this.toastr.error(this.mrTranslate.transform(data.error));
                      return;
                    }
                    else {
                      this.googleentrykey = data.ManualEntryKey;
                      this.googleqrimage = this.domSanitizer.bypassSecurityTrustUrl(data.QrCodeSetupImageUrl);
                      this.googleSetupShow = true;
                      localStorage.setItem("usegoogle_" + token["email"], "1");

                      setTimeout(() => {
                        this.input.nativeElement.focus()
                      }, 20);



                    }
                  } else {
                    this.toastr.error(
                      this.mrTranslate.transform(
                        "Es ist ein Fehler aufgetreten."
                      )
                    );
                    return;
                  }
                });
            } else {
              this.openGoogle();
            }
            return;
          }

          this.toastr.success(
            this.mrTranslate.transform("Erfolgreich eingeloggt")
          );
        } else {
          if (data.info != undefined) {
            if (data.info != '') {
              this.toastr.info(this.mrTranslate.transform(data.info));
              this.isRSVDatabaseSelectVisible = false;
              return;
            }
          }
          if (data.error == 'Server is not available') {
            this.toastr.info(this.mrTranslate.transform("MR.pro® ist zur Zeit leider nicht erreichbar. Wir sind mit der Lösung des Problems beschäftigt. Bitte versuchen sie es später noch einmal."));
          }
          else {
            this.toastr.error(this.mrTranslate.transform("Login fehlgeschlagen"));
          }
        }
      },
      (error: HttpErrorResponse) => { }
    );
  }


  getMail(event): void {
    if (this.exnewpw != undefined)
      this.exnewpw.nativeElement.value = "";

    if (this.exnewpwconf != undefined)
      this.exnewpwconf.nativeElement.value = "";

    this.pemail = this.exmail.nativeElement.value;

    if (this.pemail == "") {
      this.isforgotPWModalVisible = true;
      this.toastr.error(
        this.mrTranslate.transform(
          "Geben Sie eine gültige Email Adresse ein"
        )
      );
    } else {
      this.authService.checkemail(this.pemail).subscribe((data: any) => {
        if (this.isNumeric(data)) {

          this.doexittimer = false;
          this.dateSec = "05:00";
          this.startTimer(300);
          this.userid = Number(data);

          this.generatedNumber = Math.floor(100000 + Math.random() * 900000);
          this.authService.sendforgotemail(this.pemail, this.generatedNumber.toString()).subscribe((data: any) => {
            if (data.toLowerCase() == 'true') {
              this.exmail.nativeElement.value = '';
              this.isforgotPWModalVisible = false;

              if (this.excode != undefined)
                this.excode.nativeElement.value = "";

              this.isCodeModalVisible = true;
            }
            else {
              this.toastr.info(this.mrTranslate.transform("Bestätigungscode konnte nicht per Email versendet werden. Bitte kontaktieren sie den MR.pro Support."));
              this.isforgotPWModalVisible = false;
            }
          });
        } else {
          this.isforgotPWModalVisible = true;
          this.toastr.error(this.mrTranslate.transform("Email ist ungültig"));
        }
      }, (error: HttpErrorResponse) => {
      });
    }
  }

  isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  getValidCode(event): void {
    if (this.excode.nativeElement.value.toString().length != 6) {
      this.toastr.error(
        this.mrTranslate.transform("Bestätigungscode ist ungültig")
      );
      return;
    }
    this.pcode = this.excode.nativeElement.value;
    if (this.pcode == this.generatedNumber.toString()) {
      this.curSec1 = 300;
      this.isCodeModalVisible = false;
      this.isNewPasswordVisible = true;
      this.doexittimer = true;
    } else {
      this.isCodeModalVisible = false;
      this.excode.nativeElement.value = "";
      this.toastr.error(
        this.mrTranslate.transform("Bestätigungscode ist ungültig")
      );
      this.isNewPasswordVisible = true;
    }
  }

  setNewPassword(event): void {
    if (this.exnewpw.nativeElement.value.toString().length < 6) {
      this.toastr.error(
        this.mrTranslate.transform(
          "Passwort muss mindestens 6 Zeichen lang sein"
        )
      );
      return;
    }

    this.pw = this.exnewpw.nativeElement.value;

    if (this.pw == this.exnewpwconf.nativeElement.value) {
      this.isNewPasswordVisible = true;

      this.pwsha = shajs("sha256").update(this.pw).digest("hex");

      this.authService.updatepassword(this.pemail, this.userid.toString(), this.pwsha).subscribe((data: any) => {
        if (data.toLowerCase() == 'true') {
          this.isNewPasswordVisible = false;
          this.toastr.success(this.mrTranslate.transform("Passwort wurde geändert"));
        }
        else {
          this.toastr.error(this.mrTranslate.transform("Leider ist ein Fehler aufgetreten."));
        }
      });
    } else {
      this.isNewPasswordVisible = true;
      this.exnewpwconf.nativeElement.value = "";
      this.toastr.error(
        this.mrTranslate.transform("Passwort stimmt nicht überein")
      );
    }
  }


  startTimer(seconds: number) {
    const time = seconds;
    const timer$ = interval(1000);

    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - (sec * 100) / seconds;
      this._seconds = seconds - sec;
      (this.tminutes = Math.floor((this._seconds % 3600) / 60)),
        (this.tseconds = Math.floor(this._seconds % 60));
      this.dateSec = this.tminutes.toString().padStart(2, "0") + ":" + this.tseconds.toString().padStart(2, "0");
      this.curSec1 = sec;
      if (this.curSec1 === seconds) {
        sub.unsubscribe();
        this.isCodeModalVisible = false;
      }

      if (this.doexittimer) sub.unsubscribe();
    });
  }
}
