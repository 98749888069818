import { Component } from '@angular/core';
import { ClrCheckboxModule, ClrComboboxModule, ClrDatagridModule, ClrDropdownMenu, ClrDropdownModule, ClrFormsModule, ClrIconModule, ClrInputModule, ClrSelectModule } from '@clr/angular';
import { GenericDatagridComponent } from './generic-datagrid/generic-datagrid.component';
import { GenericDatagridComparator } from './generic-datagrid/models/generic-datagrid-comparator';
import { GenericColumn } from './generic-datagrid/models/generic-column.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { User, USERS } from './dev.data';


@Component({
  selector: 'app-dev',
  imports: [ClrDatagridModule, GenericDatagridComponent, CommonModule, FormsModule,
    ClrIconModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrFormsModule,
    ClrSelectModule,
    ClrInputModule,
    ClrComboboxModule,
    ClrCheckboxModule
  ],
  standalone: true,
  templateUrl: './dev.component.html',
  styleUrl: './dev.component.scss'
})
export class DevComponent {

  selectedUser: User | undefined = undefined;
  selectedUsers: User[] = [];
  selectionType: 'none' | 'single' | 'multi' = 'none';
  sortableColumns: string[] = [];
  filterableColumns: string[] = [];
  editableColumns: string[] = [];
  showFooter = true;
  paginationEnabled = true;
  manageColumnsEnabled = true;
  compactEnabled = true;
  showAvailableActions = true;
  showActionBar = true;
  showSingleClearSelection = true;
  showData = true;
  placeholderText: string = undefined;

  ngOnInit(): void {
    // Initial state
    this.sortableColumns = this.columns.filter(c => c.sortable).map(c => c.field);
    this.filterableColumns = this.columns.filter(c => c.filterable).map(c => c.field);
    this.editableColumns = this.columns.filter(c => c.filterable).map(c => c.field);
  }

  // Wenn du lieber setter nutzt:
  ngDoCheck(): void {
    this.columns.forEach(col => {
      col.sortable = this.sortableColumns?.includes(col.field) ?? false;
      col.filterable = this.filterableColumns?.includes(col.field) ?? false;
      col.editable = this.editableColumns?.includes(col.field) ?? false;
    });
  }


  onShowDataChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked;
    if (checked) {
      this.data = [...USERS];
    } else {
      this.data = [];
    }
  }



  data = [...USERS];

  columns: GenericColumn[] = [
    {
      field: 'id',
      label: 'ID'
    },
    {
      field: 'icon',
      label: 'Icon',
      formatter: (val: string) =>
        `<clr-icon shape="${val}" size="16"></clr-icon>`
    },
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('name', v => v.toLowerCase()),
      filterValues: this.getUniqueColumnValues(USERS, 'name')
    },
    {
      field: 'email',
      label: 'Email',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('email', v => v.toLowerCase()),
      filterValues: this.getUniqueColumnValues(USERS, 'email')
    },
    {
      field: 'age',
      label: 'Age',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('age'),
      filterValues: this.getUniqueColumnValues(USERS, 'age'),
      type: 'number'
    },
    {
      field: 'role',
      label: 'Role',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('role', v => v.toLowerCase()),
      filterValues: this.getUniqueColumnValues(USERS, 'role')
    },
    {
      field: 'createdAt',
      label: 'Created',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('createdAt', v => new Date(v).getTime()),
      filterValues: this.getUniqueColumnValues(USERS, 'createdAt'),
      type: 'date',
      formatter: (val: Date | string) =>
        new Date(val).toLocaleDateString()
    },
    {
      field: 'statusCode',
      label: 'Status',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('statusCode'),
      filterValues: this.getUniqueColumnValues(USERS, 'statusCode'),
      type: 'number',
      formatter: (val: number) => {
        switch (val) {
          case 0:
            return '<clr-icon shape="circle" style="color:gray;" size="16"></clr-icon>';
          case 5:
            return '<clr-icon shape="eye" style="color:blue;" size="16"></clr-icon>';
          case 15:
            return '<clr-icon shape="clock" style="color:orange;" size="16"></clr-icon>';
          case 25:
            return '<clr-icon shape="error-standard" style="color:red;" size="16"></clr-icon>';
          case 30:
            return '<clr-icon shape="warning-standard" style="color:darkorange;" size="16"></clr-icon>';
          case 40:
            return '<clr-icon shape="success-standard" style="color:green;" size="16"></clr-icon>';
          default:
            return val?.toString() ?? '';
        }
      }
    },
    {
      field: 'isLocked',
      label: 'Locked',
      sortable: true,
      filterable: true,
      sortBy: new GenericDatagridComparator<User>('isLocked'),
      filterValues: this.getUniqueColumnValues(USERS, 'isLocked'),
      type: 'boolean',
      formatter: (val: boolean) =>
        val
          ? '<clr-icon shape="lock" class="is-solid" style="color:red;" size="16"></clr-icon>'
          : '<clr-icon shape="unlock" class="is-solid" style="color:green;" size="16"></clr-icon>'
    }
  ];


  private getUniqueColumnValues<T>(rows: T[], field: keyof T): string[] {
    return Array.from(
      new Set(rows.map(row => row[field]?.toString() ?? ''))
    );
  }
}
