import {
  AfterViewInit,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  ClrDatagridFilterInterface,
  ClrDatagridFilter,
  ClrDatagridModule,
  ClrDatagridSortOrder
} from '@clr/angular';
import { Subject } from 'rxjs';
import { GenericRow } from '../models/generic-row.model';
import { GenericColumn } from '../models/generic-column.model';
import { FilterOperator } from '../models/filter-operator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


/**
 * This component is used to filter a column inside a grid. It accepts a column and immediate as input.
 * The first one is used to get especially the type of the data so we can correctly setup filter, like:
 * - creating the correct operator list
 * - handling formatting with correct types
 * - displaying the data correctly inside the grid
 */
@Component({
  selector: 'app-generic-filter',
  standalone: true,
  imports: [CommonModule, FormsModule, ClrDatagridModule],
  templateUrl: './generic-column-filter.component.html',
  styleUrls: ['./generic-column-filter.component.scss']
})
export class GenericFilterComponent implements ClrDatagridFilterInterface<GenericRow>, OnInit {
  @Input() column!: GenericColumn;
  @Input() immediate: boolean = true;

  searchText = '';
  dropdownValue = '';
  filteredPreview: string[] = [];
  selectedPreviewValues: string[] = [];
  activeSelectedValues: string[] = [];
  originalSelectedValues: string[] = [];

  tempSelectedValues: string[] = [];
  selectedOperator: FilterOperator = '=';
  previewColumns: GenericColumn[] = [
    {
      field: 'preview',
      label: 'Preview',
      sortable: false,
      filterable: false
    }
  ];

  sortOrder: ClrDatagridSortOrder = ClrDatagridSortOrder.DESC;
  changes = new Subject<void>();

  private getValueCount = 0;
  private updatePreviewCount = 0;
  private acceptCount = 0;
  private selectionChangedCount = 0;
  private isActiveCount = 0;
  private onChangeCount = 0;

  constructor(private filterContainer: ClrDatagridFilter<GenericRow>, public sanitizer: DomSanitizer) {
    this.filterContainer.setFilter(this);
  }

  ngOnInit(): void {
    if (!this.column) throw new Error('GenericFilterComponent requires [column]');

    if (!this.column.type || this.column.type === 'string') {
      this.selectedOperator = 'contains';
    } else if (this.column.type === 'number' || this.column.type === 'date') {
      this.selectedOperator = '=';
    } else {
      this.selectedOperator = '=';
    }

    const allValues = this.column.filterValues ?? [];

    this.activeSelectedValues = [...allValues];
    this.originalSelectedValues = [...this.activeSelectedValues];
    this.tempSelectedValues = [...this.activeSelectedValues];
    this.updateFilteredPreview();
  }

  // ngAfterViewInit() {
  //   console.log('Anchor:', this.filterContainer.anchor);
  //   console.log('SP anchor:', this.filterContainer.smartPosition.anchor);
  //   console.log('SP axis:', this.filterContainer.smartPosition.axis);
  //   console.log('SP side:', this.filterContainer.smartPosition.side);
  // }



  onApply(): void {
    this.activeSelectedValues = [...this.tempSelectedValues];
    this.originalSelectedValues = [...this.activeSelectedValues];
    this.changes.next();
    this.filterContainer.open = false;
  }

  onCancel(): void {
    this.activeSelectedValues = [...this.originalSelectedValues];
    this.tempSelectedValues = [...this.originalSelectedValues];
    this.changes.next();
    this.filterContainer.open = false;
  }

  // getValue(row: GenericRow | string, column: GenericColumn): SafeHtml | string {

  //   this.getValueCount++;
  //   // console.log(`${this.column?.field} getValue ${this.getValueCount}`);
  //   if (this.getValueCount % 1000 === 0) {
  //     console.trace(`[${this.column?.field}] getValue hit ${this.getValueCount}`);
  //   }

  //   let rawValue: any = typeof row === 'string' ? row : row[column.field];

  //   // Parse rawValue based on column.type
  //   switch (column.type) {
  //     case 'number':
  //       rawValue = Number(rawValue);
  //       break;
  //     case 'boolean':
  //       rawValue = rawValue === 'true' || rawValue === true;
  //       break;
  //     case 'date':
  //       rawValue = rawValue ? new Date(rawValue) : null;
  //       break;
  //     case 'string':
  //     default:
  //       rawValue = rawValue?.toString?.() ?? '';
  //       break;
  //   }

  //   const result = column.formatter
  //     ? column.formatter(rawValue, typeof row === 'string' ? undefined : row)
  //     : rawValue;

  //   if (typeof result === 'string' && result.includes('<')) {
  //     return this.sanitizer.bypassSecurityTrustHtml(result);
  //   }

  //   return result;
  // }
  private getValueCache = new Map<string, SafeHtml | string>();

getValue(row: GenericRow | string, column: GenericColumn): SafeHtml | string {
  const key = `${column.field}|${typeof row === 'string' ? row : row[column.field]}`;
  if (this.getValueCache.has(key)) return this.getValueCache.get(key)!;

  let rawValue = typeof row === 'string' ? row : row[column.field];

  switch (column.type) {
    case 'number': rawValue = Number(rawValue); break;
    case 'boolean': rawValue = rawValue === 'true' || rawValue === true; break;
    case 'date': rawValue = rawValue ? new Date(rawValue) : null; break;
    default: rawValue = rawValue?.toString?.() ?? ''; break;
  }

  const result = column.formatter
    ? column.formatter(rawValue, typeof row === 'string' ? undefined : row)
    : rawValue;

  const final = typeof result === 'string' && result.includes('<')
    ? this.sanitizer.bypassSecurityTrustHtml(result)
    : result;

  this.getValueCache.set(key, final);
  return final;
}



  get availableOperators(): { label: string, value: FilterOperator }[] {
    const type = this.column.type;

    switch (type) {
      case 'number':
      case 'date':
        return [
          { label: '=', value: '=' },
          { label: '!=', value: '!=' },
          { label: '<', value: '<' },
          { label: '<=', value: '<=' },
          { label: '>', value: '>' },
          { label: '>=', value: '>=' }
        ];
      case 'boolean':
        return [
          { label: 'True', value: '=' },
          { label: 'False', value: '!=' }
        ];
      case 'string':
      default:
        return [
          { label: 'contains', value: 'contains' },
          { label: 'starts with', value: 'startsWith' },
          { label: 'ends with', value: 'endsWith' },
          { label: '=', value: '=' },
          { label: '!=', value: '!=' }
        ];
    }
  }

  onChange(value: string): void {
    this.onChangeCount++;
    console.log(`${this.column?.field} onChange ${this.onChangeCount}`);


    this.selectedOperator = value as FilterOperator;
    this.updateFilteredPreview();
  }

  private acceptsCache = new Map<string, boolean>();
  private clearAcceptsCache(): void {
    this.acceptsCache.clear();
  }

  accepts(item: GenericRow): boolean {
    this.acceptCount++;
    console.log(`${this.column?.field} accepts ${this.acceptCount}`);

    const rawValue = item[this.column.field];
    const value = rawValue?.toString();

    return this.column.filterCaseInsensitve ? this.activeSelectedValues.some(v => v.toLowerCase() === value?.toLowerCase())
      : this.activeSelectedValues.includes(value);;
  }

  onSelectionChanged(values: string[]) {
    this.selectionChangedCount++;
    console.log(`${this.column?.field} onSelectionChanged ${this.selectionChangedCount}`);

    if (this.immediate) {
      this.activeSelectedValues = [...values];
      // this.originalSelectedValues = [...values];
      this.changes.next();
    } else {
      this.tempSelectedValues = [...values];
    }
  }



  updateFilteredPreview(): void {
    this.updatePreviewCount++;
    console.log(`${this.column?.field} updateFitleredPreview ${this.updatePreviewCount}`);


    console.time('updateFilteredPreview');
    const allValues = this.column.filterValues ?? [];
    const search = this.searchText.trim().toLowerCase();
    const operator = this.selectedOperator;
    const type = this.column.type ?? 'string';

    console.time('filtering');
    this.filteredPreview = allValues.filter(val => {
      const raw = val;
      const value = val.toString().toLowerCase();

      if (!search && operator !== '!=') return true; // show all if empty unless using negation

      switch (type) {
        case 'number':
          const numValue = parseFloat(value);
          const numSearch = parseFloat(search);
          if (isNaN(numValue) || isNaN(numSearch)) return false;

          switch (operator) {
            case '=': return numValue === numSearch;
            case '!=': return numValue !== numSearch;
            case '<': return numValue < numSearch;
            case '<=': return numValue <= numSearch;
            case '>': return numValue > numSearch;
            case '>=': return numValue >= numSearch;
            default: return false;
          }

        case 'date':
          const dateValue = new Date(raw);
          const dateSearch = new Date(search);
          if (isNaN(dateValue.getTime()) || isNaN(dateSearch.getTime())) return false;

          switch (operator) {
            case '=': return dateValue.getTime() === dateSearch.getTime();
            case '!=': return dateValue.getTime() !== dateSearch.getTime();
            case '<': return dateValue.getTime() < dateSearch.getTime();
            case '<=': return dateValue.getTime() <= dateSearch.getTime();
            case '>': return dateValue.getTime() > dateSearch.getTime();
            case '>=': return dateValue.getTime() >= dateSearch.getTime();
            default: return false;
          }

        case 'boolean':
          const boolSearch = search === 'true' || search === '1';
          const boolValue = value === 'true' || value === '1';
          return operator === '=' ? boolValue === boolSearch : boolValue !== boolSearch;

        case 'string':
        default:
          switch (operator) {
            case '=': return value === search;
            case '!=': return value !== search;
            case 'startsWith': return value.startsWith(search);
            case 'endsWith': return value.endsWith(search);
            case 'contains': return value.includes(search);
            default: return false;
          }
      }
    });
    console.timeEnd('filtering');

    console.time('sorting');
    // Optional: Preview-Werte nach Typ vorsortieren
    this.filteredPreview.sort((a, b) => {
      switch (type) {
        case 'number':
          return parseFloat(a) - parseFloat(b);
        case 'date':
          return new Date(a).getTime() - new Date(b).getTime();
        case 'boolean':
          return (a === 'true' || a === '1' ? 1 : 0) - (b === 'true' || b === '1' ? 1 : 0);
        case 'string':
        default:
          return a.localeCompare(b);
      }
    });

    console.timeEnd('sorting');

    console.timeEnd('updateFilteredPreview');
  }

  isActive(): boolean {
    return this.activeSelectedValues.length > 0;
  }

  trackByPreview = (_: number, item: string) => item;

}
