<!-- @if (data().length > 0) { -->

@if (selectionType() === 'none') {
<clr-datagrid [class.datagrid-compact]="compactEnabled()">
    @if(showActionBar()){
    <clr-dg-action-bar>
        <ng-content select="[datagrid-action-bar]"></ng-content>
    </clr-dg-action-bar>
    }

    @for (col of columns(); track col.field) {
    @if(col.sortable || col.filterable) {
    <clr-dg-column [clrDgField]="col.id" [clrDgSortBy]="col.sortable ? (col.sortBy ?? col.field) : undefined">
        {{ col.label }}
        @if (col.filterable) {
        <clr-dg-filter>
            <app-generic-filter [column]="col"></app-generic-filter>
            <!-- <input clrInput /> -->
        </clr-dg-filter>
        }
    </clr-dg-column>
    } @else {
    @if(manageColumns()){
    <clr-dg-column>
        <ng-container *clrDgHideableColumn="{ hidden: false }">
            {{ col.label }}
        </ng-container>
    </clr-dg-column>
    } @else {
    <clr-dg-column>
        {{ col.label }}
    </clr-dg-column>
    }
    }
    }

    @if(placeholder()){
    <clr-dg-placeholder>{{ placeholder() }}</clr-dg-placeholder>
    }

    <clr-dg-row #datagridRow *clrDgItems="let row of data(); trackBy: trackByField" [clrDgItem]="row"
        [clrDgSelectable]="!row.isLocked">
        @if(showActionOverflows()){
        <clr-dg-action-overflow>
            <ng-content select="[datagrid-action-overflow]"></ng-content>
        </clr-dg-action-overflow>
        }

        @for (col of columns(); track col.field) {
        @if(col.editable){
        <clr-dg-cell>
            <clr-input-container style="margin-top: 0;">
                <input clrInput [value]="getValue(row, col)" />
            </clr-input-container>
        </clr-dg-cell>
        } @else {
        <clr-dg-cell [innerHTML]="getValue(row, col)"></clr-dg-cell>
        }
        }
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>
            <ng-content select="[datagrid-detail-header]"></ng-content>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
            <ng-content select="[datagrid-detail-content]"></ng-content>
        </clr-dg-detail-body>
    </clr-dg-detail>

    @if(showFooter() && paginationEnabled()) {
    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="pageSizes()">{{ footerLabel() }} per page</clr-dg-page-size>
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
            {{ pagination.totalItems }} {{ footerLabel() }}
        </clr-dg-pagination>
    </clr-dg-footer>

    } @else if(showFooter()) {
    <clr-dg-footer>
        {{ totalCount() ?? data().length }} {{ footerLabel() }}
    </clr-dg-footer>
    }



</clr-datagrid>
}

@else if (selectionType() === 'single') {
<clr-datagrid deselectButton [isVisible]="showSingleClearSelection()" [class.datagrid-compact]="compactEnabled()"
    [(clrDgSingleSelected)]="_selectedItem" (clrDgSingleSelectedChange)="onSingleSelectedChange($event)">
    @if(showActionBar()){
    <clr-dg-action-bar>
        <ng-content select="[datagrid-action-bar-single]"></ng-content>
    </clr-dg-action-bar>
    }

    @for (col of columns(); track col.field) {
    @if(col.sortable || col.filterable) {
    <clr-dg-column [clrDgField]="col.id" [clrDgSortBy]="col.sortable ? (col.sortBy ?? col.field) : undefined">
        {{ col.label }}
        @if (col.filterable) {
        <clr-dg-filter>
            <app-generic-filter [column]="col"></app-generic-filter>
        </clr-dg-filter>
        }
    </clr-dg-column>
    } @else {
    @if(manageColumns()){
    <clr-dg-column>
        <ng-container *clrDgHideableColumn="{ hidden: false }">
            {{ col.label }}
        </ng-container>
    </clr-dg-column>
    } @else {
    <clr-dg-column>
        {{ col.label }}
    </clr-dg-column>
    }
    }
    }

    <clr-dg-row #datagridRow *clrDgItems="let row of data(); trackBy: trackByField" [clrDgItem]="row"
        [clrDgSelectable]="!row.isLocked">
        @if(showActionOverflows()){
        <clr-dg-action-overflow>
            <ng-content select="[datagrid-action-overflow-single]"></ng-content>
        </clr-dg-action-overflow>
        }

        @for (col of columns(); track col.field) {
        @if(col.editable){
        <clr-dg-cell>
            <clr-input-container style="margin-top: 0;">
                <input clrInput [value]="getValue(row, col)" />
            </clr-input-container>
        </clr-dg-cell>
        } @else {
        <clr-dg-cell [innerHTML]="getValue(row, col)"></clr-dg-cell>
        }
        }
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>
            <ng-content select="[datagrid-detail-header]"></ng-content>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
            <ng-content select="[datagrid-detail-content]"></ng-content>
        </clr-dg-detail-body>
    </clr-dg-detail>

    @if(showFooter() && paginationEnabled()) {
    <clr-dg-footer>
        <clr-dg-pagination [clrDgPageSize]="pageSize">
            <clr-dg-page-size>
                {{ footerLabel() }}
                <clr-option [clrValue]="5">5</clr-option>
                <clr-option [clrValue]="10">10</clr-option>
                <clr-option [clrValue]="20">20</clr-option>
                <clr-option [clrValue]="50">50</clr-option>
            </clr-dg-page-size>
            {{ totalCount() ?? data().length }} {{ footerLabel() }}
        </clr-dg-pagination>
    </clr-dg-footer>

    }
    @else if(showFooter()){
    <clr-dg-footer>
        {{ totalCount() ?? data().length }} {{ footerLabel() }}
    </clr-dg-footer>
    }
</clr-datagrid>
}

@else if (selectionType() === 'multi') {
<clr-datagrid [class.datagrid-compact]="compactEnabled()" [clrDgSelected]="_selectedItems"
    [clrDgSelectable]="!row.isLocked" (clrDgSelectedChange)="onMultiSelectedChange($event)">
    @if(showActionBar()){
    <clr-dg-action-bar>
        <ng-content select="[datagrid-action-bar-multi]"></ng-content>
    </clr-dg-action-bar>
    }

    @for (col of columns(); track col.field) {
    @if(col.sortable || col.filterable) {
    <clr-dg-column [clrDgField]="col.id" [clrDgSortBy]="col.sortable ? (col.sortBy ?? col.field) : undefined">
        {{ col.label }}
        @if (col.filterable) {
        <clr-dg-filter>
            <app-generic-filter [column]="col"></app-generic-filter>
        </clr-dg-filter>
        }
    </clr-dg-column>
    } @else {
    @if(manageColumns() && col.managable){
    <clr-dg-column>
        <ng-container *clrDgHideableColumn="{ hidden: false }">
            {{ col.label }}
        </ng-container>
    </clr-dg-column>
    } @else {
    <clr-dg-column>
        {{ col.label }}
    </clr-dg-column>
    }
    }
    }

    <clr-dg-row #datagridRow *clrDgItems="let row of data(); trackBy: trackByField" [clrDgItem]="row">
        @if(showActionOverflows()){
        <clr-dg-action-overflow>
            <ng-content select="[datagrid-action-overflow-multi]"></ng-content>
        </clr-dg-action-overflow>
        }

        @for (col of columns(); track col.field) {
        @if(col.editable){
        <clr-dg-cell>
            <clr-input-container style="margin-top: 0;">
                <input clrInput [value]="getValue(row, col)" />
            </clr-input-container>
        </clr-dg-cell>
        } @else {
        <clr-dg-cell [innerHTML]="getValue(row, col)"></clr-dg-cell>
        }
        }
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>
            <ng-content select="[datagrid-detail-header]"></ng-content>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
            <ng-content select="[datagrid-detail-content]"></ng-content>
        </clr-dg-detail-body>
    </clr-dg-detail>

    @if(showFooter() && paginationEnabled()) {
    <clr-dg-footer>
        <clr-dg-pagination [clrDgPageSize]="pageSize">
            <clr-dg-page-size>
                {{ footerLabel() }}
                <clr-option [clrValue]="5">5</clr-option>
                <clr-option [clrValue]="10">10</clr-option>
                <clr-option [clrValue]="20">20</clr-option>
                <clr-option [clrValue]="50">50</clr-option>
            </clr-dg-page-size>
            {{ totalCount() ?? data().length }} {{ footerLabel() }}
        </clr-dg-pagination>
    </clr-dg-footer>

    }
    @else if(showFooter()) {
    <clr-dg-footer>
        {{ totalCount() ?? data().length }} {{ footerLabel() }}
    </clr-dg-footer>
    }
</clr-datagrid>
}

<!-- } -->