<app-cameramodal [parentChildConnection]="openModal" (modalClosed)="onModalClose()"></app-cameramodal>

<clr-modal *ngIf="bildData" [(clrModalOpen)]="showBildModal" [clrModalSize]="'lg'" class="customModal"
  [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
  <div class="modal-body">
    <img class="modalImage" [src]="bildData" />
  </div>
</clr-modal>

    <div class="overlay-inner-wrapper">
      <div id="bauwerksWrapper">
        <header class="header-2">
          <div class="branding">
              <span class="title">{{title | mrTranslate}}</span>
          </div>
          <div class="header-actions">
            <a href="javascript://" class="nav-link nav-icon">
              <clr-icon  shape="times" (click)="close()"></clr-icon >
            </a>
          </div>
        </header>
        <div class="clr-row"  style="margin-left: 10px;">
          <div class="clr-col-4">
            <div  style="margin-top:36px;">
              <div class="btn-group btn-primary btn-icon">
                <button class="btn btn-success" (click)="initBue()">
                  <clr-icon shape="file"></clr-icon>
                  <span class="clr-icon-title">Neu</span>
                </button>
                <button class="btn btn-success-outline" [disabled]="!table?.rows?.length" (click)="createExcelDoc()">
                  <clr-icon shape="excel"></clr-icon>
                  <span class="clr-icon-title">Löschen</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <clr-datagrid *ngIf="table" class="datagrid-compact dg-overflow-fix" style="min-height: 200px; margin-bottom: 36px; margin-left:10px; max-width: calc(50%);">
          <clr-dg-column *ngFor="let column of table['columns']" [clrDgField]="column.id"
            [hidden]="column.id | hideIDColumns:['b_parentid','typ','bezirk','bezeichnungstamm']">
            {{ column.id | titlecase | mrTranslate }}
          </clr-dg-column>

          <!-- [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem" (clrDgSingleSelectedChange)="selectionFirmaChanged($event)" -->
          <clr-dg-row *clrDgItems="let row of table['rows']" [clrDgItem]="row"
            [clrDgSelected]="selectedBue">
            <clr-dg-cell class="name-cell" *ngFor="let cell of table['columns']"
              [hidden]="cell.id | hideIDColumns:['b_parentid','typ','bezirk','bezeichnungstamm']" [innerHTML]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{table['rows']?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>

      <div class="picContainer"  style="margin-left: 0px; gap: 10px;">
        <!-- <div *ngFor="let thumb of previewThumbnails" class="uploadImagePreviewContainer"
          style="max-height: 120px; max-width: 160px;">
          <a class="deletePreview" href="javascript:void(0);"
            (click)="previewThumbnails.splice((previewThumbnails.indexOf(thumb)),1)">
            <clr-icon shape="times-circle" class="is-solid"></clr-icon>
          </a>
          <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(thumb)">
            <clr-icon shape="search"></clr-icon>
          </a>
          <img [src]="thumb.src" alt="" class="uploadImagePreview">
        </div> -->
        <label></label>
        <label>vst Min</label>
        <label>vst Max</label>


        <label>Quadrant I</label>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(0)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[0] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(0)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(1)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[1] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(1)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>


        <label>Quadrant II</label>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(2)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[2] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(2)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(3)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[3] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(3)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>


        <label>Quadrant III</label>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(4)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[4] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(4)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(5)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[5] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(5)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>


        <label>Quadrant IV</label>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(6)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[6] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(6)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>
        <div class="uploadImagePreviewContainer" style="border: 1px solid #3700ff;">
          <a class="addPic" href="javascript:void(0);" (click)="openCamera(7)">
            <clr-icon shape="camera" class="is-solid"></clr-icon>
          </a>
          <ng-container *ngIf="previewThumbnails[7] as image">
            <a class="deletePreview" href="javascript:void(0);" (click)="deleteBILDBue(7)">
              <clr-icon shape="times-circle" class="is-solid"></clr-icon>
            </a>
            <a class="deletePreview1" href="javascript:void(0);" (click)="showPreviewImage(image)">
              <clr-icon shape="search"></clr-icon>
            </a>
            <div>
              <img [src]="image.src" alt="" class="uploadImagePreview">
            </div>
          </ng-container>
        </div>
        </div>

      <!-- <div class="clr-row" style="margin-top:36px; height:160px; margin-left: 10px; width: 95vw; border-style: solid; vertical-align: middle;">
        <ng-container *ngIf="os === 'Windows'; else camera">
          <button class="btn" type="button" (click)="openCamera(undefine)" style="margin-top:60px; margin-right: 10px;">
            <clr-icon shape="camera"></clr-icon> {{'Foto' | mrTranslate}}
          </button>
        </ng-container>

        <ng-template #camera>
          <button class="btn"  type="button" (click)="fileInput.click()" style="margin-top:60px; margin-right: 10px;">
            <clr-icon shape="image-gallery"></clr-icon> {{'Foto' | mrTranslate}}
          </button>
          <input type="file" #fileInput id="fileInput" accept="image/*" capture="camera" name="fileInput"
            style="display: none;" multiple (change)="onFileChange($event)">
        </ng-template>



      </div> -->
    </div>





<clr-modal [(clrModalOpen)]="showSVGModal" [clrModalSize]="'xl'" [clrModalClosable]="true">
  <h3 class="modal-title">{{ 'Berechnung nach BÜV NE' | mrTranslate }}</h3>
  <div class="modal-body" style="margin-left:10px">
    <div class="clr-row">
      <clr-radio-container clrInline class="margin-0-t">
        <label>{{'Bereich' | mrTranslate}}</label>
        <clr-radio-wrapper>
          <input type="radio" name="SelQuadrant" clrRadio checked="true" (click)="setQuad(0);" class="clr-radio"/>
          <label>{{'Quadrant 1+2' | mrTranslate}}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input type="radio" name="SelQuadrant" clrRadio (click)="setQuad(1);" class="clr-radio"/>
          <label>{{'Quadrant 3+4' | mrTranslate}}</label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </div>
    <form clrForm clrLayout="horizontal" [formGroup]="formBueValues">
      <div class="clr-row container">
        <label>Berechnung: </label>
        <div class="clr-select-wrapper">
          <select class="clr-select" style="width: 135px;" formControlName="bersa" (change)="selChanged()">
            <option value="Kraftfahrzeug">Kraftfahrzeug</option>
            <option value="Fußgänger">Fußgänger</option>
          </select>
        </div>
        <label>VSt (km/h) Min</label>
        <ng-container *ngIf="formBueValues.value.bersa == 'Fußgänger'; else veMinAlt">
          <div class="clr-select-wrapper">
            <select class="clr-select" style="width: 95px;" formControlName="vstmin" (change)="selChanged()">
              <option value="1.4">1,4</option>
              <option value="1">1</option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="formBueValues.value.bersa == 'Fußgänger'; else veMaxAlt">
          <label>VSt (m/s)</label>
          <div class="clr-select-wrapper">
            <select class="clr-select" style="width: 95px;" formControlName="vstmax" (change)="selChanged()">
              <option value="1.4">1,4</option>
              <option value="1">1</option>
            </select>
          </div>
        </ng-container>
        <label>VE (km/h)</label>
        <div class="clr-select-wrapper">
          <select class="clr-select" style="width: 95px;" formControlName="ve" (change)="selChanged()">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="70">70</option>
            <option value="80">80</option>
          </select>
        </div>
      </div>
      <ng-template #veMinAlt>
        <div class="clr-select-wrapper">
          <select class="clr-select" style="width: 95px;" formControlName="vstmin" (change)="selChanged()">
            <option value="10">10</option>
            <option value="5">5</option>
          </select>
        </div>
      </ng-template>
      <ng-template #veMaxAlt>
        <label>VSt (km/h)</label>
        <div class="clr-select-wrapper">
          <select class="clr-select" style="width: 95px;" formControlName="vstmax" (change)="selChanged()">
            <option value="50">50 u. 40</option>
            <option value="30">30</option>
            <option value="20">20</option>
            <option value="10">10</option>
            <option value="5">5</option>
          </select>
        </div>
      </ng-template>

      <div style="position: relative;">
        <img src="assets/icons/BUE_Cal_n.svg" class="mw-100-p light" style="margin-top: 10px;" />

        <input type="number"
          style="position: absolute;
          top: 212px;
          left: 410px;
          width: 60px;
          border: 1px solid #000;
          background-color: white;" formControlName="d" value="0" class="clr-input combobox"
          (change)="onChange($event)"
          min="3"/>


          <input type="number"
          style="position: absolute;
          top: 335px;
          left: 230px;
          width: 80px;
          border: 1px solid #000;
          background-color: white;" formControlName="sa" value="0" class="clr-input combobox"
          readonly="true"/>

      </div>
    </form>
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="showSVGModal = false">{{ 'Abbrechen' | mrTranslate }}</button>
      <button type="button" class="btn btn-outline" (click)="saveBue()">{{ 'Speichern' | mrTranslate }}</button>
  </div>
</clr-modal>
