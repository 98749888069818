
export interface User {
    id: number;
    icon: string;
    name: string;
    email: string;
    age: number;
    role: 'Admin' | 'User';
    isLocked: boolean;
    createdAt: Date;
    statusCode: 0 | 5 | 15 | 25 | 30 | 40;
}

export const USERS: User[] = [
    // Explicit users
    {
        id: 1,
        icon: 'user',
        name: 'Alice Smith',
        email: 'alice@example.com',
        age: 32,
        role: 'Admin',
        isLocked: false,
        createdAt: new Date('2024-01-01'),
        statusCode: 25
    },
    {
        id: 2,
        icon: 'user',
        name: 'Bob Johnson',
        email: 'bob@example.com',
        age: 28,
        role: 'User',
        isLocked: true,
        createdAt: new Date('2024-01-15'),
        statusCode: 0
    },
    {
        id: 3,
        icon: 'user',
        name: 'Charlie Brown',
        email: 'charlie@example.com',
        age: 41,
        role: 'User',
        isLocked: false,
        createdAt: new Date('2024-02-10'),
        statusCode: 5
    },
    {
        id: 4,
        icon: 'user',
        name: 'Diana Prince',
        email: 'diana.prince@example.com',
        age: 35,
        role: 'Admin',
        isLocked: false,
        createdAt: new Date('2023-11-20'),
        statusCode: 40
    },
    {
        id: 5,
        icon: 'user',
        name: 'Ethan Hunt',
        email: 'ethan.hunt@example.com',
        age: 38,
        role: 'User',
        isLocked: true,
        createdAt: new Date('2024-03-10'),
        statusCode: 15
    },
    {
        id: 6,
        icon: 'user',
        name: 'Fiona Gallagher',
        email: 'fiona.g@example.com',
        age: 30,
        role: 'Admin',
        isLocked: false,
        createdAt: new Date('2023-12-05'),
        statusCode: 5
    },
    {
        id: 7,
        icon: 'user',
        name: 'George Martin',
        email: 'g.martin@example.com',
        age: 50,
        role: 'User',
        isLocked: false,
        createdAt: new Date('2024-01-25'),
        statusCode: 30
    },
    {
        id: 8,
        icon: 'user',
        name: 'Hannah Lee',
        email: 'hannah.lee@example.com',
        age: 22,
        role: 'User',
        isLocked: true,
        createdAt: new Date('2024-02-18'),
        statusCode: 0
    },
    {
        id: 9,
        icon: 'user',
        name: 'Isaac Newton',
        email: 'isaac.newton@example.com',
        age: 45,
        role: 'Admin',
        isLocked: false,
        createdAt: new Date('2024-01-08'),
        statusCode: 25
    },
    {
        id: 10,
        icon: 'user',
        name: 'Julia Roberts',
        email: 'julia.roberts@example.com',
        age: 36,
        role: 'User',
        isLocked: false,
        createdAt: new Date('2024-03-01'),
        statusCode: 5
    },
    // Auto-generated users (randomized)
    ...Array.from({ length: 160 }).map((_, i) => {
        const firstNames = ['Liam', 'Emma', 'Noah', 'Olivia', 'Ava', 'Mason', 'Sophia', 'Logan', 'Isabella', 'Lucas'];
        const lastNames = ['Walker', 'Harris', 'Young', 'Allen', 'King', 'Wright', 'Scott', 'Torres', 'Nguyen', 'Hill'];
        const random = (arr: string[]) => arr[Math.floor(Math.random() * arr.length)];
        const id = i + 11;
        const name = `${random(firstNames)} ${random(lastNames)}`;
        const email = `${name.toLowerCase().replace(/ /g, '.')}@example.com`;
        const age = Math.floor(Math.random() * 45) + 18;
        const role = Math.random() > 0.3 ? 'User' : 'Admin';
        const isLocked = Math.random() > 0.7;
        const createdAt = new Date(2023 + Math.floor(Math.random() * 2), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28) + 1);
        const allowedStatusCodes: (0 | 5 | 15 | 25 | 30 | 40)[] = [0, 5, 15, 25, 30, 40];
        const statusCode = allowedStatusCodes[Math.floor(Math.random() * allowedStatusCodes.length)];


        return {
            id,
            icon: 'user',
            name,
            email,
            age,
            role,
            isLocked,
            createdAt,
            statusCode
        } satisfies User;
    })
];