import { Routes } from "@angular/router";
import { OktaCallbackComponent } from "@okta/okta-angular";
import { AnlagenComponent } from "./content/Anlagen/anlagen.component";
import { AuftraegeComponent } from "./content/Auftraege/auftraege.component";
import { BegehungComponent } from "./content/begehung/begehung.component";
import { DashboardComponent } from "./content/Dashboard/dashboard.component";
import { GISMapComponent } from "./content/GISMap/gismap.component";
import { HomeComponent } from "./content/home/home.component";
import { KalenderComponent } from "./content/Kalender/kalender.component";
import { LoginComponent } from "./content/Login/login.component";
import { MaengelComponent } from "./content/Maengel/maengel.component";
import { WartungComponent } from "./content/Wartung/wartung.component";
import { AuthGuard } from "./guards/auth.guard";
import { DevComponent } from "./content/Dev/dev.component";

const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'login/callback',
        component: OktaCallbackComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'home',
        redirectTo: 'karte'
    },
    {
        path: 'dev',
        component: DevComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'karte',
        component: GISMapComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'anlagen',
        component: AnlagenComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'pruefkalender',
        component: KalenderComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'maengel',
        component: MaengelComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'auftraege',
        component: AuftraegeComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'begehung',
        component: BegehungComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'wartung',
        component: WartungComponent,
        canActivate: [AuthGuard],
    },
];

export default appRoutes;