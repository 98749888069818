import {
  Component, Input, Output, EventEmitter, OnInit, OnDestroy, AfterViewInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  ClrDatagridFilter, ClrAlignment,
  ClrCommonFormsModule, ClrSelectModule, ClrInputModule, ClrDatagridModule,
  ClrStopEscapePropagationDirective, ClrPopoverHostDirective
} from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, TitleCasePipe, NgSwitchCase, NgSwitch, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';

import { FilterFactory } from '../../../../shared/filter/filterfactory';
import { DataType, emptyValueSign, isBooleanType } from '../../../../models/ui/datatype';
import { TablePrettyPrintPipe } from '../../../../pipes/tablePrettyPrint.pipe';
import { OperatorFactory, isEmptyOperator, isNotEmptyOperator, noneOperator } from '../../../../shared/filter/operatorfactory';
import { Column } from 'src/app/models/ui/table';
import { FilterItem, FilterOperator } from 'src/app/models/ui/filter';
import { Lifecycled } from 'src/app/decorators/class-decorators';
import { IFilter } from 'src/app/shared/filter/ifilter';
import { MrTranslatePipe } from '../../../../pipes/mr-translate.pipe';

export enum ColumnFilterAction {
  Cancel,
  Reset,
  Submit
}

export class ColumnFilterChange {
  tableName: string;
  columnId: string;
  items: any[];
  reason: ColumnFilterAction;
}

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss'],
  standalone: true,
  imports: [
    ClrCommonFormsModule, FormsModule, ClrSelectModule, ClrInputModule,
    ClrDatagridModule, NgIf, NgFor, TitleCasePipe,
    TablePrettyPrintPipe, MrTranslatePipe,
    ClrStopEscapePropagationDirective, ClrPopoverHostDirective,
    NgTemplateOutlet
  ]
})

@Lifecycled()
export class ColumnFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tableName: string;
  @Input() column: Column;
  @Input() uniqueColumnValues: Set<any>;
  @Output() changes = new EventEmitter<ColumnFilterChange>();

  searchValue: string = '';
  operator: FilterOperator;
  input1: string = '';
  input2: string = '';
  selectedItems: FilterItem[] = [];
  operators: FilterOperator[];
  filterFactory = new FilterFactory();
  filter: IFilter | undefined;
  dataType: DataType = "string";
  originalItems: FilterItem[] = [];
  filteredItems: FilterItem[] = [];
  enableSecondSearchInput = false;
  inputsDisabled = false;
  modalOpened = false;
  private skipCheckStateOnDestroy = false;

  // Store the last submitted state
  private lastSubmittedOperator: FilterOperator;
  private lastSubmittedInput1: string = '';
  private lastSubmittedInput2: string = '';
  private lastSubmittedSelectedItems: FilterItem[] = [];
  private initialized = false;

  constructor(
    private filterContainer: ClrDatagridFilter
  ) {
    filterContainer.smartPosition.anchor = ClrAlignment.END;
    filterContainer.openChange.subscribe(opened => {
      console.log("open filter container change!");
      if (!this.initialized && opened == true) {
        console.log("init data");

        this.initialized = true;
        this.initData();
      }
    });
  }
  ngAfterViewInit(): void {
    // this.dataType = (this.column.type?.toLowerCase() ?? "string") as DataType;
    let type = (this.column.type?.toLowerCase() ?? "string") as DataType;

    // if (
    //   ['int8', 'int16', 'int32', 'int64', 'uint16', 'uint32', 'uint64', 'single', 'decimal', 'string'].includes(type)
    //   && this.uniqueColumnValues?.size
    // ) {
    //   const rawValues = Array.from(this.uniqueColumnValues);
    //   if (this.detectIfBooleanType(rawValues)) {
    //     type = 'boolean';
    //   }
    // }

    // this.dataType = type;


    this.dataType = type;

    if (!this.dataType) {
      console.error(`The column ${this.column.id} - ${this.column.bezeichnung} doesn't seem to have a proper data type. Please inform the administrator if you see this as an issue.`);
      return;
    }
    this.operators = new OperatorFactory().getOperators(this.dataType);
    this.operator = this.operators[0];
    // this.initData();

    requestAnimationFrame(() => {
      setTimeout(() => {
        this.updateIconStyle(true);
      }, 0)
    })
  }

  ngOnDestroy(): void {
    if (!this.skipCheckStateOnDestroy) {
      this.closeCancel();
      this.skipCheckStateOnDestroy = false;
    }
  }

  ngOnInit(): void {

  }

  private updateIconStyle(isActive: boolean) {
    const columnId = `datagrid-${this.tableName}-col-${this.normalizeId(this.column.id)}`;
    const iconSelector = `#${columnId} cds-icon`;
    const buttonSelector = `#${columnId} .datagrid-filter-toggle`;

    const icon = document.querySelector(iconSelector);
    const button = document.querySelector(buttonSelector);
    if (icon && button) {
      this.setFilterIconAndButtonClass(isActive, icon, button);
    } else {
      if (!icon) console.debug(`Icon element is null for column ${columnId}`);
      if (!button) console.debug(`Button element is null for column ${columnId}`);
    }
  }

  setFilterIconAndButtonClass(isActive: boolean, icon: Element, button: Element): void {
    icon.setAttribute('shape', isActive ? 'filter-grid-circle' : 'filter-grid');
    button.classList.toggle('datagrid-filtered', isActive);
  }

  normalizeId(id: string): string {
    return id.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
  }

  initData(): void {
    try {
      const values = typeof this.uniqueColumnValues === "undefined" || !this.uniqueColumnValues ? [] : [...this.uniqueColumnValues];
      if (values.length > 0) {
        this.originalItems = values
          .map((value, index) => ({
            id: index,
            name: value?.toString() ?? emptyValueSign,
            isVisible: true,
          }))
          .filter(x => x && x.name !== "")
          .sort((a, b) => {
            const aNum = Number(a.name);
            const bNum = Number(b.name);

            const aIsNum = !isNaN(aNum);
            const bIsNum = !isNaN(bNum);

            if (aIsNum && bIsNum) {
              return aNum - bNum; // sort numerically
            } else if (!aIsNum && !bIsNum) {
              return a.name.localeCompare(b.name); // sort alphabetically
            } else {
              return aIsNum ? -1 : 1; // numbers come before strings
            }
          });
      }


      this.filter = this.filterFactory.createFilter(this.originalItems, this.dataType);
      this.filteredItems = [...this.originalItems];
      this.selectedItems = [...this.originalItems];
      this.saveLastSubmittedState(); // Save initial state
      console.log("data type", this.dataType);
      console.log("filtered items", this.filteredItems);
    } catch (exception) {
      console.error(exception);
    }
  }

  onOperatorChange(operator: FilterOperator): void {
    this.filterItems();
    this.enableSecondSearchInput = operator?.value === "between";
    this.inputsDisabled = operator?.value === isEmptyOperator.value || operator?.value === isNotEmptyOperator.value;
  }

  searchInputChanged(inputValue: InputEvent): void {
    this.input1 = (inputValue.target as HTMLInputElement).value;
    this.filterItems();
  }

  searchInput2Changed(inputValue: InputEvent): void {
    this.input2 = (inputValue.target as HTMLInputElement).value;
    this.filterItems();
  }

  forceResetSubmit() {
    this.modalOpened = false;
    this.operator = this.operators[0];
    this.input1 = '';
    this.input2 = '';
    this.selectedItems = [...this.originalItems];
    this.filteredItems = [...this.originalItems];
    this.emitFilterChange(ColumnFilterAction.Reset, this.selectedItems);
    this.skipCheckStateOnDestroy = true;
    this.updateIconStyle(this.selectedItems.length > 0);
  }

  closeSubmit(): void {
    this.saveLastSubmittedState();
    this.emitFilterChange(ColumnFilterAction.Submit, this.selectedItems);
    this.filterContainer.open = false;
    this.skipCheckStateOnDestroy = true;
    this.updateIconStyle(this.selectedItems.length > 0);
  }

  closeCancel(): void {
    this.restoreLastSubmittedState();
    this.emitFilterChange(ColumnFilterAction.Cancel, this.selectedItems);
    this.filterContainer.open = false;
    this.skipCheckStateOnDestroy = true;
    this.updateIconStyle(this.selectedItems.length > 0);
  }

  saveLastSubmittedState(): void {
    this.lastSubmittedOperator = this.operator;
    this.lastSubmittedInput1 = this.input1;
    this.lastSubmittedInput2 = this.input2;
    this.lastSubmittedSelectedItems = [...this.selectedItems];
    this.updateIconStyle(this.selectedItems.length > 0);
  }

  restoreLastSubmittedState(): void {
    this.operator = this.lastSubmittedOperator;
    this.input1 = this.lastSubmittedInput1;
    this.input2 = this.lastSubmittedInput2;
    this.selectedItems = [...this.lastSubmittedSelectedItems];
  }

  emitFilterChange(reason: ColumnFilterAction, items: any[]) {
    this.changes.next({
      columnId: this.column.id,
      tableName: this.tableName,
      items: items,
      reason: reason,
    });
  }

  filterItems(): void {
    try {
      let input1 = this.input1;
      let input2 = this.input2;
      const isInputIndependentOperator = this.operator === undefined || this.operator === isNotEmptyOperator || this.operator === isEmptyOperator;

      if (!this.filter || (!isInputIndependentOperator && (!input1 || input1.length === 0))) {
        this.filteredItems = this.filter.filter("", "", noneOperator);
        input1 = "";
        input2 = "";
        this.filteredItems = [... this.originalItems];
      } else {
        this.filteredItems = this.filter.filter(input1, input2, this.operator);
      }
      this.selectedItems = [...this.filteredItems];
      console.log("on filter - filtered items", this.filteredItems);
      console.log("on filter - selected items", this.selectedItems);

    } catch (error) {
      console.error(error);
    }
  }

  resetItems(): void {
    this.filteredItems = [...this.originalItems];
    this.selectedItems = [...this.originalItems];

    console.log("on reset - filtered items", this.filteredItems);
    console.log("on reset - selected items", this.selectedItems);
  }

  get isOperatorVisible() {
    return !isBooleanType(this.dataType);
  }

  get isInputVisible() {
    return this.operator ? !isBooleanType(this.dataType) && this.operator.value !== isEmptyOperator.value && this.operator.value !== isNotEmptyOperator.value : true;
  }

  trackById(index: number, item: FilterItem) {
    return item.id;
  }

  parseLfShape(value: string): string {
    const num = parseFloat(value);
    if (isNaN(num) || value === '0') {
      return 'blank';
    }
    return 'lastelle' + (num / 10).toString().replace('.', '_');
  }

  private iconColumns = ['icon', 'typ', 'rep', 'sp', 'lf', 'st', 'si'];
  isIconColumn(colId: string): boolean {

    return this.dataType === 'boolean' || this.iconColumns.includes(colId.toLowerCase());
  }

  getIconShape(colId: string, value: string, id: string): string {
    console.log(colId, value, id);

//debugger


    switch (colId) {
      case 'typ':
      case 'icon':
        return value ? 'objekttyp' + id : 'unknown-status';
      case 'rep':
        return value === '0' ? 'shield-check' : 'shield-x';
      case 'sp':
        return value && value !== '0' ? 'richtung' + value : 'blank';
      case 'lf': {
        const num = parseFloat(value);
        return isNaN(num) || value === '0'
          ? 'blank'
          : 'lastelle' + (num / 10).toString().replace('.', '_');
      }
      case 'st':
        return value === '1' ? 'exclamation-triangle' : 'blank';
      case 'si':
        return value === '1' ? 'warning-standard' : 'blank';
    }

    switch (this.dataType) {
      case 'boolean':
        if (value === 'true' || value === '1') return 'success-standard';
        if (value === 'false' || value === '0') return 'circle';
        return 'help';
    }

    return 'blank';
  }

  getIconStyle(colId: string, value: string): { [klass: string]: any } | null {
    if (colId === 'rep') {
      return { color: value === '0' ? 'green' : 'red' };
    }
    if (colId === 'si') {
      return { color: 'red' };
    }
    if (this.dataType === 'boolean') {
      return value === 'true' || value === '1'
        ? { color: '#2EC0FF' }
        : { opacity: 0.2 };
    }
    return null;
  }


  // private detectIfBooleanType(values: any[]): boolean {
  //   const truthy = ['true', '1', true, 1];
  //   const falsy = ['false', '0', false, 0];

  //   return values.every(v =>
  //     truthy.includes(v) || falsy.includes(v)
  //   );
  // }

  getIconColor(colId: string, value: string): string | null {
    if (colId === 'rep') {
      return value === '0' ? 'green' : 'red';
    }
    if (colId === 'si') {
      return 'red';
    }
    if (this.dataType === 'boolean') {
      return value === 'true' || value === '1' ? '#2EC0FF' : null;
    }
    return null;
  }

  getIconOpacity(colId: string, value: string): string | null {
    if (this.dataType === 'boolean' && (value === 'false' || value === '0')) {
      return '0.2';
    }
    return null;
  }

  getIconSize(colId: string): string {
    if (this.dataType === 'boolean' && !this.isIconColumn(colId)) {
      return '16';
    }
    return '28';
  }
}
