import { CommonModule, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, input, output, QueryList, ViewChildren } from '@angular/core';
import { ClrDatagridModule, ClrInputModule } from '@clr/angular';
import { GenericColumn } from './models/generic-column.model';
import { GenericRow } from './models/generic-row.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GenericFilterComponent } from './generic-column-filter/generic-column-filter.component';
import { DeselectSingleSelectedDirective } from 'src/app/directives/deselect-single-selected.directive';


/**
 * This data grid is a wrapper around the clarity grid for better reusability and easier angular integration and also for angular 19 adjustments.
 * It supports the following:
 * - display total count of items (always)
 * - sorting (input)
 * - filtering (input)
 * - selection (none, single or multi) with two way binding
 * - pagination with configurable page sizes (input)
 * - manage columns - configurable (input) !!Hint: Current limitation to non-editable and filterable columns!!
 * - custom rendering of cells (by column functions)
 * - auto hide "id" columns (not configurable but id is mandatory)
 * - editable cells (by column property)
 * - compact mode (by input)
 * - action tool bar rendering (by templating)
 * - action column rendering  for the popup (by templating)
 * - show footer (true/false by input)
 * - show details pane (by templating)
 * - show clear selection for single selection
 * - placeholder text if no data was provided
 * - endless/virtual scrolling - tbd
 */
@Component({
  selector: 'app-generic-datagrid',
  imports: [CommonModule, ClrDatagridModule, GenericFilterComponent, ClrInputModule, DeselectSingleSelectedDirective],
  templateUrl: './generic-datagrid.component.html',
  styleUrls: ['./generic-datagrid.component.scss'],
  standalone: true
})
export class GenericDatagridComponent implements AfterViewInit {
  @ViewChildren('datagridRow', { read: ElementRef }) datagridRows!: QueryList<ElementRef>;


  readonly columns = input<GenericColumn[]>();
  readonly data = input<GenericRow[]>();
  readonly loading = input<boolean>(false);
  readonly totalCount = input<number | undefined>();
  readonly footerLabel = input<string>('Items');
  readonly selectionType = input<'none' | 'single' | 'multi'>('none');
  readonly paginationEnabled = input<boolean>(true);
  readonly pageSizes = input<number[]>([10, 20, 50, 100]);
  readonly manageColumns = input<boolean>(true);
  readonly compactEnabled = input<boolean>(false);
  readonly showActionBar = input<boolean>(false);
  readonly showActionOverflows = input<boolean>(false);
  readonly showFooter = input<boolean>(true);
  readonly showSingleClearSelection = input<boolean>(true);
  readonly selectedItem = input<GenericRow | undefined>();
  readonly selectedItems = input<GenericRow[]>([]);
  readonly placeholder = input<string>();

  protected _selectedItem?: GenericRow;
  protected _selectedItems: GenericRow[] = [];

  selectedItemChange = output<GenericRow | undefined>();
  selectedItemsChange = output<GenericRow[] | undefined>();

  constructor(private sanitizer: DomSanitizer) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.datagridRows.forEach(row => {
        const el = row.nativeElement;
        // TODO: This can be improvied, maybe do it once for the first row and then just apply it to all rows
        const height = el.getBoundingClientRect().height;
        el.style.minHeight = `${height}px`;
        el.style.maxHeight = `${height}px`;
      });
    });
  }



  get visibleColumns(): GenericColumn[] {
    return this.columns().filter(c => c.field !== 'id');
  }

  trackByField = (_: number, row: GenericRow) => {
    return row?.id ?? _
  };

  getValue(row: GenericRow | string, column: GenericColumn): SafeHtml | string {
    const rawValue: string = typeof row === 'string' ? row : row[column.field];
    if (column.editable) {
      if (column.type === 'date')
        return column.formatter(rawValue);
      return rawValue;
    }

    const result = column.formatter ? column.formatter(rawValue) : rawValue;
    // If result looks like HTML, sanitize it
    if (typeof result === 'string' && result.includes('<')) {
      return this.sanitizer.bypassSecurityTrustHtml(result);
    }

    return result;
  }

  get isMultiSelection() {
    return this.selectionType() === 'multi';
  }

  get isSingleSelection() {
    return this.selectionType() === 'single';
  }

  get selectedItemValue(): GenericRow | undefined {
    return this._selectedItem ?? this.selectedItem();
  }

  get selectedItemsValue(): GenericRow[] {
    return this._selectedItems.length > 0 ? this._selectedItems : this.selectedItems();
  }


  onMultiSelectedChange(items: GenericRow[]) {
    this._selectedItems = items;
    this.selectedItemsChange.emit(items);
  }

  onSingleSelectedChange(item: GenericRow | undefined) {
    this._selectedItem = item;
    this.selectedItemChange.emit(item);
  }
}
