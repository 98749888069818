import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oktaGroupName'
})
export class OktaGroupNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    // Remove trailing ".User" or ".Admin" (case-insensitive)
    let cleaned = value.replace(/\.(user|admin)$/i, '');

    // Replace remaining dots with spaces
    return cleaned.replace(/\./g, ' ');
  }
}
