import { Component, Inject, OnInit } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AuthService, LoginMethod } from 'src/app/services/Auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { APIService } from 'src/app/services/APIService/api.service';
import { MsalConfigService } from 'src/app/services/msal-config.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true
})
export class HomeComponent implements OnInit {
  userName?: string;
  error?: Error;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private apiService: APIService,
    private msalConfigService: MsalConfigService,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const oktaSuccess = await this.tryLoginWithOkta();
      if (oktaSuccess) return;

      const msSuccess = await this.tryLoginWithMicrosoft();
      if (msSuccess) return;

      this.router.navigateByUrl('/login');
    } catch (err) {
      console.error('Authentication error:', err);
      this.handleError(err);
      this.router.navigateByUrl('/login');
    }
  }

  async tryLoginWithOkta(): Promise<boolean> {
    let isLoggedInToOkta = await this.oktaAuth.isAuthenticated();

    if (!isLoggedInToOkta && window.location.search.includes('iss=')) {
      isLoggedInToOkta = await this.tryLoginOktaSSO();
    }

    if (isLoggedInToOkta) {
      try {
        console.log("SSO Method: Okta");
        await this.loginToApiUsingOkta();
        return true;
      } catch (error) {
        console.error('Error logging into API using Okta:', error);
        return false;
      }
    }

    return false;
  }

  async tryLoginOktaSSO(): Promise<boolean> {
    try {
      const tokenResponse = await this.oktaAuth.token.getWithoutPrompt({
        scopes: ['openid', 'profile', 'email', 'groups-mrpro'],
        responseMode: 'okta_post_message'
      });

      this.oktaAuth.tokenManager.setTokens(tokenResponse.tokens);
      window.history.replaceState({}, document.title, window.location.pathname);
      return await this.oktaAuth.isAuthenticated();
    } catch (error) {
      await this.oktaAuth.signInWithRedirect({
        scopes: ['openid', 'profile', 'email', 'groups-mrpro']
      });
      return false;
    }
  }

  async loginToApiUsingOkta(): Promise<void> {
    const userClaims = await this.oktaAuth.getUser();
    const idToken = this.oktaAuth.getIdToken();
    const decodedToken = this.oktaAuth.token.decode(idToken) as any;
    const groupsTheUserIsIn = decodedToken.payload["groups-mrpro"] || [];

    this.userName = userClaims.name;

    const oktaLoginRequest = {
      email: userClaims.email,
      username: this.userName,
      givenName: userClaims.given_name,
      familyName: userClaims.family_name,
      usergruppen: groupsTheUserIsIn,
    };

    console.log("GRUPPEN INITIAL", JSON.stringify(groupsTheUserIsIn));

    const tokenResponse = await firstValueFrom(this.authService.getOktaMRproToken(oktaLoginRequest));
    if(!tokenResponse) {
      throw 'Logged in to Okta but could not retrieve the API token';
    }
    sessionStorage.removeItem('okta-jwt-tmp');
    this.authService.loginMethod = LoginMethod.Okta;
    this.authService.handleLogin(tokenResponse);
    this.router.navigateByUrl('/anlagen');
  }

  async tryLoginWithMicrosoft(): Promise<boolean> {
    if (await this.msalConfigService.isInitialized()) {
      const instance = await this.msalConfigService.getInstance();
      if(!instance) return false;

      const token = await instance.acquireTokenSilent({ scopes: ['user.read'] });
      const account = instance.getAllAccounts()?.[0];
      console.log('Logged in as:', account?.username);

      await this.loginToApiUsingMSAL(account.username, token.accessToken)
      return true;
    }

    return false;
  }

  async loginToApiUsingMSAL(email: string, accessToken: string): Promise<void> {
    const result = await this.authService.msalLogin(email, accessToken).toPromise();

    if (result.error !== undefined) {
      this.toastr.error(this.translateService.instant("Login fehlgeschlagen"));
      this.router.navigateByUrl('/login');
      return;
    }

    this.authService.loginMethod = LoginMethod.Microsoft;
    localStorage.setItem('loginMethod', "Microsoft");

    const val = await firstValueFrom(this.authService.get$token());

    const allowComponents = {
      map:
        getTokenRight(val.rights.view, "AllowMAP") &&
        this.authService.decrypt(localStorage.getItem("hasMap")) === "true",
      statistic: getTokenRight(val.rights.view, "AllowSTAT"),
    };

    if (allowComponents.map) {
      this.router.navigateByUrl('/karte');
    } else {
      this.router.navigateByUrl('/anlagen');
    }
  }

  private handleError(error: Error) {
    this.error = error;
    const errorMessage = this.translateService.instant("An error occurred");
    this.toastr.error(errorMessage);
    this.sendErrorToLog(error);
  }


  private sendErrorToLog(error: Error): void {
    const errorPayload = {
      message: error.message,
      stack: error.stack,
      timestamp: new Date().toISOString()
    };

    // Send error payload to the backend via POST.
    this.apiService.logError(errorPayload).subscribe({
      next: () => console.log("Error logged successfully."),
      error: (err) => console.error("Failed to log error", err)
    });
  }

}

export function getTokenRight(rights: any[], key: string) {
  return rights.find(r => r.key === key)?.value;
}
