<div class="clr-form clr-form-compact clr-form-horizontal"
  style="margin-bottom: 1rem; display: flex; flex-wrap: wrap; gap: 1rem; flex-direction: column;">

  <!-- Selection Type -->
  <div class="clr-form-control">
    <label for="select-selection-type" class="clr-control-label">Selection Type</label>
    <div class="clr-control-container">
      <div class="clr-select-wrapper">
        <select id="select-selection-type" class="clr-select" [(ngModel)]="selectionType">
          <option value="none">None</option>
          <option value="single">Single</option>
          <option value="multi">Multi</option>
        </select>
      </div>
      <span class="clr-subtext">Choose the selection mode</span>
    </div>
  </div>

  <!-- Sortable Columns -->
  <clr-combobox-container>
    <label>Sortable Columns</label>
    <clr-combobox clrMulti="true" [(ngModel)]="sortableColumns" name="sortableColumns">

      <ng-container *clrOptionSelected="let selected">
        {{ selected }}
      </ng-container>

      <clr-options>
        <clr-option *clrOptionItems="let col of columns; field: 'label'" [clrValue]="col.field">
          {{ col.label }}
        </clr-option>
      </clr-options>

    </clr-combobox>
    <clr-control-helper>Select one or more columns to enable sorting</clr-control-helper>
  </clr-combobox-container>

  <!-- Filterable Columns -->
  <clr-combobox-container>
    <label>Filterable Columns</label>
    <clr-combobox clrMulti="true" [(ngModel)]="filterableColumns" name="filterableColumns">

      <ng-container *clrOptionSelected="let selected">
        {{ selected }}
      </ng-container>

      <clr-options>
        <clr-option *clrOptionItems="let col of columns; field: 'label'" [clrValue]="col.field">
          {{ col.label }}
        </clr-option>
      </clr-options>

    </clr-combobox>
    <clr-control-helper>Select one or more columns to enable filtering</clr-control-helper>
    <clr-control-error>There was an error</clr-control-error>
  </clr-combobox-container>

  <!-- Editable Columns -->
  <clr-combobox-container>
    <label>EditableColumns</label>
    <clr-combobox clrMulti="true" [(ngModel)]="editableColumns" name="editableColumns">

      <ng-container *clrOptionSelected="let selected">
        {{ selected }}
      </ng-container>

      <clr-options>
        <clr-option *clrOptionItems="let col of columns; field: 'label'" [clrValue]="col.field">
          {{ col.label }}
        </clr-option>
      </clr-options>

    </clr-combobox>
    <clr-control-helper>Select one or more columns to enable editing</clr-control-helper>
    <clr-control-error>There was an error</clr-control-error>
  </clr-combobox-container>

  <div style="display:flex; align-items: center;">

    <!-- Show data toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="showData" [(ngModel)]="showData" (change)="onShowDataChange($event)" />
        <label>Show data</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Footer toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="showFooter" [(ngModel)]="showFooter" />
        <label>Show footer</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Pagination toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="paginationEnabled" [(ngModel)]="paginationEnabled" />
        <label>Enable Pagination</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Manage columns toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="manageColumnsEnabled" [(ngModel)]="manageColumnsEnabled" />
        <label>Enable columns management</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Compact grid toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="compactEnabled" [(ngModel)]="compactEnabled" />
        <label>Enable compact mode</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>


  </div>
  <div style="display:flex; align-items: center;">
    <!-- Show action bar grid toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="showActionBar" [(ngModel)]="showActionBar" />
        <label>Show action bar (with examples)</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Show available actions grid toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="showAvailableActions" [(ngModel)]="showAvailableActions" />
        <label>Show available actions (with examples)</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <!-- Show clear selection for single selection toggle -->
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input clrCheckbox type="checkbox" value="showSingleClearSelection" [(ngModel)]="showSingleClearSelection" />
        <label>Show single clear selection button</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>
  </div>

  <div style="display:flex; align-items: center;">
    <clr-input-container>
      <label style="min-width: fit-content;">Placeholder</label>
      <input clrInput type="text" [(ngModel)]="placeholderText"/>
    </clr-input-container>
  </div>


</div>


<app-generic-datagrid [data]="data" [columns]="columns" footerLabel="Users" [selectionType]="selectionType"
  [paginationEnabled]="paginationEnabled" [manageColumns]="manageColumnsEnabled" [compactEnabled]="compactEnabled"
  [(selectedItems)]="selectedUsers" [(selectedItem)]="selectedUser" [showActionBar]="showActionBar"
  [showActionOverflows]="showAvailableActions" [showFooter]="showFooter"
  [showSingleClearSelection]="showSingleClearSelection" [placeholder]="placeholderText">

  <!-- Action Bar -->
  <div datagrid-action-bar-single>
    <ng-container *ngTemplateOutlet="sharedActionBar"></ng-container>
  </div>
  <div datagrid-action-bar-multi>
    <ng-container *ngTemplateOutlet="sharedActionBar"></ng-container>
  </div>
  <div datagrid-action-bar>
    <ng-container *ngTemplateOutlet="sharedActionBar"></ng-container>
  </div>

  <!-- Action Overflow -->
  <div datagrid-action-overflow>
    <ng-container *ngTemplateOutlet="sharedActionOverflow"></ng-container>
  </div>
  <div datagrid-action-overflow-single>
    <ng-container *ngTemplateOutlet="sharedActionOverflow"></ng-container>
  </div>
  <div datagrid-action-overflow-multi>
    <ng-container *ngTemplateOutlet="sharedActionOverflow"></ng-container>
  </div>

  <!-- Detail Pane -->
  <div datagrid-detail-header>
    <ng-container *ngTemplateOutlet="sharedDetailPaneHeader"></ng-container>
  </div>
  <div datagrid-detail-header-single>
    <ng-container *ngTemplateOutlet="sharedDetailPaneHeader"></ng-container>
  </div>
  <div datagrid-detail-header-multi>
    <ng-container *ngTemplateOutlet="sharedDetailPaneHeader"></ng-container>
  </div>


  <div datagrid-detail-content>
    <ng-container *ngTemplateOutlet="sharedDetailPaneContent"></ng-container>
  </div>
  <div datagrid-detail-content-single>
    <ng-container *ngTemplateOutlet="sharedDetailPaneContent"></ng-container>
  </div>
  <div datagrid-detail-content-multi>
    <ng-container *ngTemplateOutlet="sharedDetailPaneContent"></ng-container>
  </div>

</app-generic-datagrid>

<ng-template #sharedActionBar>
  <div class="btn-group">
    <button type="button" class="btn btn-sm btn-secondary">Add to group</button>
    <button type="button" class="btn btn-sm btn-secondary">Delete</button>
    @if(selectedUser || selectedUsers.length === 1) {
    <button type="button" class="btn btn-sm btn-secondary">Edit</button>
    }
  </div>
  <div class="btn-group">
    <clr-dropdown>
      <button type="button" class="btn btn-sm btn-secondary" clrDropdownTrigger>
        Export
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
        <button type="button" clrDropdownItem>Export All</button>
        <button type="button" [disabled]="selectedUsers.length === 0" clrDropdownItem>
          Export Selected Items
        </button>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</ng-template>


<ng-template #sharedActionOverflow>
  <button class="action-item">Custom Overflow</button>
  <button class="action-item">Another</button>
</ng-template>


<ng-template #sharedDetailPaneHeader>
  Details of {{ selectedUser?.name }}
</ng-template>

<ng-template #sharedDetailPaneContent>
  <clr-dg-detail-body>
    <clr-input-container style="margin-left: 3px">
      <label>ID</label>
      <input clrInput type="text" value="{{selectedUser?.id}}" #bez />
    </clr-input-container>
    <clr-input-container style="margin-left: 3px">
      <label>Label</label>
      <input clrInput type="text" value="{{selectedUser?.label}}" #bez />
    </clr-input-container>
    <br />
  </clr-dg-detail-body>
</ng-template>