import { Injectable } from '@angular/core';
import { ColumnFilterChange, ColumnFilterAction } from './column-filter/column-filter.component';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private originalData: any[] = [];
  private changes: { [columnId: string]: ColumnFilterChange } = {};

  constructor(data: any[]) {
    this.originalData = data;
  }

  clearFilters() {
    this.changes = {};
  }

  setChanges(changes: any) {
    this.changes = changes || {};
    return this.filterData();
  }
  getChanges(): any {
    return this.changes;
  }

  filter(columnFilterChange: ColumnFilterChange): any[] {
    console.log(`Action: ${ColumnFilterAction[columnFilterChange.reason]}`);
    console.log(`Before filtering: ${this.originalData.length} items`);

    if (columnFilterChange.reason !== ColumnFilterAction.Cancel) {
      const filters = columnFilterChange.items;
      if (filters.length > 0) {
        this.changes[columnFilterChange.columnId] = columnFilterChange;
      } else {
        delete this.changes[columnFilterChange.columnId];
      }
      const result = this.filterData();
      console.log(`After filtering: ${result.length} items`);
      return result;
    }

    console.log(`Cancel detected – returning unfiltered original data`);
    return this.originalData;
  }

  private filterItemsByColumn(item: any, columnId: string): boolean {
    const normalizedColumnId = columnId.toLowerCase();

    // Find the actual key in 'this.changes' in a case-insensitive way
    const entryKey = Object.keys(this.changes).find(k => k.toLowerCase() === normalizedColumnId);
    if (!entryKey) return true;

    const filterEntry = this.changes[entryKey];
    const filterItems = filterEntry.items;

    return filterItems.some(filterItem => {
      const itemKey = Object.keys(item).find(key => key.toLowerCase() === normalizedColumnId);
      if (!itemKey) return false;

      const itemValue = item[itemKey]?.toString().toLowerCase();
      return itemValue === filterItem.name.toString().toLowerCase();
    });
  }


  private filterData(): any[] {
    console.time('filterData');
    let filteredData;
    if (Object.keys(this.changes).length > 0) {
      filteredData = this.originalData.filter(item => {
        return Object.keys(this.changes).every(columnId => this.filterItemsByColumn(item, columnId));
      });
    } else {
      filteredData = [...this.originalData];
    }
    console.timeEnd('filterData');
    return filteredData;
  }
}
