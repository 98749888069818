import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarCommonModule, DateAdapter } from 'angular-calendar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { Logoutinterceptor } from './app/services/LogoutInterceptor/logoutinterceptor';
import OktaAuth from '@okta/okta-auth-js';
import {
  TranslateDirective
} from "@ngx-translate/core";

import dayjs from 'dayjs';

import { registerLocaleData } from '@angular/common';

import * as localeDJDe from 'dayjs/locale/de';
import * as localeDJEn from 'dayjs/locale/en';
import * as localeDJFr from 'dayjs/locale/fr';
import * as localeDJIt from 'dayjs/locale/it';
import * as localeDJNl from 'dayjs/locale/nl';
import * as localeDJRu from 'dayjs/locale/ru';
import * as localeDJPt from 'dayjs/locale/pt';
import * as localeDJTr from 'dayjs/locale/tr';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import localeTr from '@angular/common/locales/tr';
import { MrTranslatePipe } from './app/pipes/mr-translate.pipe';
import { urlEnDecodePipe } from './app/pipes/urlEncode.pipe';
import { TablePrettyPrintPipe } from './app/pipes/tablePrettyPrint.pipe';
import { HideIDColumnsPipe } from './app/pipes/hide-idcolumns.pipe';
import { WerteRowValuesPipe } from './app/content/_components/_overlays/zaehlerdialog/zaehlerverwaltung/werte-row-values.pipe';
import { MrUnitTextPipe } from './app/pipes/mrUnitText.pipe';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ObjTypePipe } from './app/pipes/objtype.pipe';
import { DynamicLocaleId } from './app/models/ui/translation/dynamic-locale-id.model';
import appRoutes from './app/app.routes';

const oktaAuth = new OktaAuth({
  issuer: `https://${environment.okta.domain}/oauth2/default`,
  clientId: `${environment.okta.clientId}`,
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
  scopes: ['openid', 'profile', 'email', 'groups-mrpro'],
  responseType: ['code', 'id_token', 'token', 'refresh_token'],
  responseMode: 'query',
  storageManager: {
    token: {
      storageTypes: ['cookie'],
    }
  },
  tokenManager: {
    autoRenew: true
  },
  cookies: {
    secure: true,
    sameSite: 'none'
  }
});

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localeRu);
registerLocaleData(localePt);
registerLocaleData(localeTr);

dayjs.locale(localeDJDe);
dayjs.locale(localeDJEn);
dayjs.locale(localeDJFr);
dayjs.locale(localeDJIt);
dayjs.locale(localeDJNl);
dayjs.locale(localeDJRu);
dayjs.locale(localeDJPt);
dayjs.locale(localeDJTr);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, ClarityModule, FormsModule, ReactiveFormsModule, TranslatePipe, TranslateDirective,
      ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
          deps: [HttpClient],
        },
      }),
      CalendarCommonModule.forRoot({
        provide: DateAdapter,
        useFactory: () => adapterFactory(dayjs),
      }),
      OktaAuthModule.forRoot({ oktaAuth })),

    // * if Servise has providedIn: "root", there is no need to declare here
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: HTTP_INTERCEPTORS, useClass: Logoutinterceptor, multi: true },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: OktaAuth, useValue: oktaAuth },
    { provide: APP_BASE_HREF, useValue: '/' },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes),
    provideAnimations(),
    MrTranslatePipe,
    urlEnDecodePipe,
    TablePrettyPrintPipe,
    HideIDColumnsPipe,
    WerteRowValuesPipe,
    DatePipe,
    ObjTypePipe,
    MrUnitTextPipe,
    {
      provide: AngularDeviceInformationService,
      useFactory: (platformId: Object) => new AngularDeviceInformationService(platformId),
      deps: [PLATFORM_ID]
    }
  ]
})
  .catch(err => console.error(err));
