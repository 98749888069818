import { ClrDatagridComparatorInterface } from '@clr/angular';
import { GenericRow } from './generic-row.model';

export class GenericDatagridComparator<T extends GenericRow> implements ClrDatagridComparatorInterface<T> {
  constructor(
    private field: keyof T,
    private transformer?: (value: any, row: T) => any
  ) {}

  compare(a: T, b: T): number {
    const aValue = this.getValue(a);
    const bValue = this.getValue(b);

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return -1;
    if (bValue == null) return 1;

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }

    return aValue.toString().localeCompare(bValue.toString());
  }

  private getValue(row: T): any {
    const raw = row[this.field];
    return this.transformer ? this.transformer(raw, row) : raw;
  }
}
